<template>
    <div class="container">
            <br>    
            <div class="row">
                <div class="col-md-12 ">
                    <p class="request-step-title">Добавление объектов для заверения</p>
                </div>
            </div>
            
            <b-form @submit.prevent="onSubmit">
            <!-- ***** Features Items Start ***** -->
            <div class="row">
                <div class="col-md-12"> 
                <b-card no-body style="width:100%; border-color:#CEE1F8">
                <b-tabs card>
                    <!-- Render Tabs -->
                    <b-tab :title="`Объект ${index+1}`" v-for="(object, index) in form.objects" :key="index" :active="active_tab">
                        <div class="col-md-12">
                            <b-form-group id="urlInputGroup"
                                label="Адрес интернет-страницы"
                                label-for="urlInput"
                                class="form-element">
                                <b-form-input id="urlInput"
                                    type="url"
                                    v-model="object.url"
                                    @input="checkUrl(object.url)"
                                    @change="urlToUnicode(object.url)"
                                    required
                                    placeholder="http://www.example.ru">
                                </b-form-input>
                            </b-form-group>
                            <div v-if="errField[index].item" class="" style="margin-bottom:10px">
                               <p class="text-danger">Введите корректный url адрес</p>
                            </div>

                            <b-form-group id="actionsInputGroup" :description="actionsDescription">
                                <b-form-checkbox :id="`action${index}`"
                                    class="col-md-12"
                                    v-model="object.is_action"
                                    value="1"
                                    unchecked-value="0">
                                Требуются действия на странице
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group v-if="object.is_action == 1" id="infoInputGroup"
                                    class="form-element">
                                <b-form-textarea id="infoTextarea"
                                    v-model="object.actions"
                                    placeholder="Опишите дополнительные действия которые необходимо произвести на странице если таковые имеются"
                                    :rows="3"
                                    :max-rows="8">
                                </b-form-textarea>
                            </b-form-group>
                            <b-form-group id="authInputGroup">
                                <b-form-checkbox :id="`auth${index}`"
                                    class="col-md-12"
                                    v-model="object.is_auth"
                                    value="1"
                                    unchecked-value="0">
                                Требуются авторизация
                                </b-form-checkbox>
                            </b-form-group>
                            <b-form-group id="hr-desc" description="Укажите кол-во файлов/документов размещенныx на этой интернет странице">
                                <b-form-group
                                    id="fieldset-horizontal"
                                    label-cols-sm="10"
                                    label-cols-lg="11"
                                    description=""
                                    label="Необходимо распечатать и заверить файлы (документы)"
                                    label-for="input-horizontal"
                                    class="form-element-sm"
                                >
                                    <b-form-input id="input-horizontal" type="number" min="0" max="100" number v-model="object.certFilesQty" @keypress="onlyNumber"></b-form-input>
                                </b-form-group>
                            </b-form-group>

                            <b-form-group id="hr-desc" description="Укажите кол-во файлов/документов размещенныx на этой интернет странице">
                                <b-form-group
                                    id="fieldset-horizontal"
                                    label-cols-sm="10"
                                    label-cols-lg="11"
                                    description=""
                                    label="Необходимо записать на оптический диск и заверить файлы (документы)"
                                    label-for="input-horizontal"
                                    class="form-element-sm"
                                >
                                    <b-form-input id="input-horizontal" type="number" min="0" max="100" number v-model="object.saveFilesQty" @keypress="onlyNumber"></b-form-input>
                                </b-form-group>
                            </b-form-group>

                            <b-form-group id="hr-desc" description="Укажите кол-во видеофайлов размещенныx на этой интернет странице">
                                <b-form-group
                                    id="fieldset-horizontal"
                                    label-cols-sm="10"
                                    label-cols-lg="11"
                                    description=""
                                    label="Необходимо записать на оптический диск и заверить видеофайлы"
                                    label-for="input-horizontal"
                                    class="form-element-sm"
                                >
                                    <b-form-input id="input-horizontal" type="number" min="0" max="100" number v-model="object.saveVideoQty" @keypress="onlyNumber"></b-form-input>
                                </b-form-group>
                            </b-form-group>

                            <b-form-group id="htmlInputGroup">
                                <b-form-checkbox :id="`html${index}`"
                                    class="col-md-12"
                                    v-model="object.is_html"
                                    value="1"
                                    unchecked-value="0">
                                Заверить html-код этой страницы
                                </b-form-checkbox>
                            </b-form-group>
                           
                            <b-btn v-if="form.objects.length > 1" size="sm" variant="outline-danger" style="margin-bottom:20px" class="float-right" @click="()=>removeTab(index)">
                                Удалить
                            </b-btn>
                            
                        </div>
                        <!-- </b-form> -->
                    </b-tab>

                    <!-- New Tab Button (Using tabs slot) -->
                    
                    <template v-slot:tabs-end>
                        <b-nav-item slot="tabs" @click.prevent="newTab" href="#">
                            <i class="fa fa-plus"></i> Добавить
                        </b-nav-item>
                    </template>

                    <!-- Render this if no tabs -->
                    <div slot="empty" class="text-center text-muted">
                    Адреса объектов ещё не добавлены.
                    <br> Для того что бы добавить адрес объекта нажмите Добавить.
                    </div>
                </b-tabs>
                </b-card>
                </div>
                
            </div>
            <div class="row" style="margin-top:10px">
                <div class="col-md-6"> 
                    <b-form-group id="info"
                            label="Предмет спора (цель заверения)"
                            label-for="issue"
                            class="form-element">
                        <b-form-select v-model="form.issue" :options="issues"></b-form-select>
                    </b-form-group>
                   
                </div>
            </div>
            <div class="row">
                <div class="col-md-12"> 
                    <b-form-group id="info"
                            label="Доп. информация"
                            label-for="info"
                            class="form-element">
                        <b-form-textarea id="info"
                            v-model="form.info"
                            placeholder=""
                            :rows="4"
                            :max-rows="6">
                        </b-form-textarea>
                    </b-form-group>
                </div>
            </div>
            
            <b-card no-body text-variant="white" class="overflow-hidden block-sticky" style="max-width: 100%; padding:10px">
                <b-row no-gutters>
                    <b-col md="12" class="text-center">
                        <span style="font-size: 20px; margin-right: 20px">Стоимость заверения: {{siteTotal}} р.</span>
                        <button type="submit" class="btn-secondary-box" :disabled="formSend"><i v-if="formSend" class="fas fa-spinner fa-pulse"></i> Отправить заявку</button>
                    </b-col>
                </b-row>
            </b-card>
            </b-form>
            <br>
    </div>
</template>

<script>
import axios from 'axios';
import urlapi from 'url';
import punycode from 'punycode';

export default {
    data () {
        return {
            bc: [
                {text: 'Список заявок', href: '/'}, 
                {text: 'Создание заявки', active: true}
            ],
            form: {
                type:'site',
                 objects: [
                    {
                    url:'',
                    is_action: 0,
                    actions: '',
                    is_auth: 0,
                    is_html: 0,
                    saveFilesQty: '',
                    saveVideoQty: '',
                    certFilesQty: ''
                    },
                ],
                issue: '',
                info: '',
            },
            claim_num: '',
            show: true,
            active_tab: false,
            loading: false,
            formSend: false,
            errorMsg: '',
            errField: [{item:false}],
            actionsDescription: "Открыть изображение, открыть вкладку, авторизация,  осмотр html-кода, запись видео/др. файлов на цифровой носитель, заверение файлов/документов и тд.",
        }
    },
    props: {
        tariffs: {},
        issues: {},
    },
    mounted(){
       this.$store.commit('SET_LOADER', false)
    },
    computed: {
        siteTotal(){
            let ts = parseInt(this.tariffs.spec_auto)
            if (this.isComplicated()) {
                ts = parseInt(this.tariffs.spec)
            }
            return this.tariffs.notarius
                + ts
                + parseInt(this.qtyObjects())
                + parseInt(this.sumAction())
                + parseInt(this.sumAuth())
                + parseInt(this.sumCertFiles()) 
                + parseInt(this.sumSaveFiles()) 
                + parseInt(this.sumSaveVideos()) 
                + parseInt(this.sumHtml())
        },
    },
    methods: {
         isComplicated(){
            let res = false
            if (parseInt(this.form.objects.length) > parseInt(this.tariffs.auto_qty)) {
                res = true
            }
            let checks = this.form.objects.filter((item) => {return item.is_action == 1 || item.is_auth == 1 || item.is_html == 1}).length
            let inp = this.form.objects.filter((item) => {return item.saveFilesQty >= 1 || item.saveVideoQty >=1 || item.certFilesQty >=1}).length
            let url = 0
            let checkUrls = this.tariffs.urls || []
            checkUrls.forEach((value) => {
                url += this.form.objects.filter((item) => {return item.url.indexOf(value) >= 1}).length
            })
            if(checks > 0 || inp > 0 || url > 0){
                res = true
            }
            return res
        },
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46  && keyCode !== 8) { // 46 delete, 8 backspace
                    $event.preventDefault();
            }
        },
        onSubmit () {
            if(this.form.objects.length > 0){
                this.formSend = true;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
                axios.post(this.$store.state.apiUrl+`/claim/new`, {
                    body: this.form
                }).then(response => {
                    this.formSend = false;
                    this.$router.push('/')
                    this.claim_num = response.data.num;
                    // this.protocol_type = response.data.type;
                    // this.form_visible = false;
                    // setTimeout(()=>{
                    //     this.protocolDone = true;
                    // },30000);
                    
                }).catch((error) => {
                    // eslint-disable-next-line
                    console.log(error);
                    this.formSend = false;
                    this.errorMsg = 'Произошла ошибка'
                });
            }else{
                this.errorMsg = 'Необходимо добавить хотя бы один объект осмотра'
                return false
            }
        },
        removeTab (x) {
            this.form.objects.splice(x, 1)
        },
         newTab () {
            if(this.check(this.form.objects[this.form.objects.length-1].url)){
                this.errField[this.form.objects.length-1].item = false
                this.errField.push({item: false})
                this.form.objects.push({
                    url:'',
                    is_action: 0,
                    actions: '',
                    is_auth: 0,
                    is_html: 0,
                    saveFilesQty: '',
                    saveVideoQty: '',
                    certFilesQty: ''
                });
                this.active_tab = true;
            }else{
                this.errField[this.form.objects.length-1].item = true
            }
        },
        check(url) {
            if (/^(http|https)/.test(url)){
                return true
            }else{
                return false
            }
        },
        urlToUnicode(url){
            let urlObj = urlapi.parse(url)
            urlObj.hostname = punycode.toUnicode(urlObj.hostname)
            urlObj.host = punycode.toUnicode(urlObj.host)
            // console.log(urlObj)
            this.form.objects[this.form.objects.length-1].url = urlapi.format(urlObj)
        },
        checkUrl(url){
            if(this.check(url)){
                this.errField[this.form.objects.length-1].item = false
            }else{
                this.errField[this.form.objects.length-1].item = true
            }
        },
        qtyObjects(){
            if (parseInt(this.form.objects.length) > parseInt(this.tariffs.object.limitSite) && parseInt(this.form.objects.length) < parseInt(this.tariffs.object.discountStartQty)){
                let extObj = parseInt(this.form.objects.length) - parseInt(this.tariffs.object.limitSite)
                return parseInt(this.tariffs.object.tariffSite) * extObj
            }
            if (parseInt(this.form.objects.length) >= parseInt(this.tariffs.object.discountStartQty)){
                return this.regressionDiscountSum()
            }
            return 0
        },
        sumAction(){
            let count = this.form.objects.filter((item)=>{return item.is_action == 1}).length
            return count * parseInt(this.tariffs.object.action)
        },
        sumAuth(){
            let count = this.form.objects.filter((item)=>{return item.is_auth == 1}).length
            return count * parseInt(this.tariffs.object.auth)
        },
        sumCertFiles(){
            let sum = 0
            this.form.objects.forEach((item) => {
                if (parseInt(item.certFilesQty) >= 1) {
                    sum += parseInt(item.certFilesQty) * parseInt(this.tariffs.object.file)
                }
            })
            return sum
        },
        sumSaveFiles(){
            let sum = 0
            this.form.objects.forEach((item, i) => {
                    if(item.saveFilesQty == 1 && i == 0 ){
                        sum += parseInt(this.tariffs.object.record.firstFile)
                    }
                    if(parseInt(item.saveFilesQty) > 1 && i == 0){
                        sum += parseInt(this.tariffs.object.record.firstFile) + (parseInt(item.saveFilesQty) - 1)  * parseInt(this.tariffs.object.record.nextFile)
                    }
                     if(parseInt(item.saveFilesQty) > 0 && i !== 0){
                        sum += parseInt(item.saveFilesQty)  * parseInt(this.tariffs.object.record.nextFile)
                    }
            })
            return sum
        },
        sumSaveVideos(){
            let sum = 0
            this.form.objects.forEach((item, i) => {
                    if(item.saveVideoQty == 1 && i == 0 ){
                        sum += parseInt(this.tariffs.object.record.firstVideo)
                    }
                    if(parseInt(item.saveVideoQty) > 1 && i == 0){
                        sum += parseInt(this.tariffs.object.record.firstVideo) + (parseInt(item.saveVideoQty) - 1)  * parseInt(this.tariffs.object.record.nextVideo)
                    }
                     if(parseInt(item.saveVideoQty) > 0 && i !== 0){
                        sum += parseInt(item.saveVideoQty)  * parseInt(this.tariffs.object.record.nextVideo)
                    }
            })
            return sum
        },
        sumHtml(){
            let count = this.form.objects.filter((item)=>{return item.is_html == 1}).length
            return count * parseInt(this.tariffs.object.html)
        },
        regressionDiscountSum(){
            let beforeDiscount = parseInt(this.tariffs.object.tariffSite) * (parseInt(this.tariffs.object.discountStartQty) - 2)
            let discountObjects = (parseInt(this.form.objects.length) + 1) - parseInt(this.tariffs.object.discountStartQty)

            let discountSum = 0
            let discountTariff = parseInt(this.tariffs.object.tariffSite)
            for (let i = 0; i < discountObjects; i++) {
                discountTariff -= parseInt(this.tariffs.object.discountStep)
                if(discountTariff > this.tariffs.object.discountThreshold){
                    discountSum += parseInt(discountTariff)
                }else{
                    discountSum += parseInt(this.tariffs.object.discountThreshold)
                }
            }
            return parseInt(beforeDiscount) + parseInt(discountSum)
        },
	},
}
</script>

<style scoped>
     #fieldset-horizontal {
       margin-bottom: -5px;
   }
   #infoTextarea {
       margin-bottom: 0;
   }
    .block-sticky {
        position: sticky;
        bottom: -5px;
        background-color: #3a96d8;
        border:0;
    }
</style>