<template>
    <div class="container">
      <h2>Компания: {{ company.name }}</h2>
  
      <!-- Текущий тариф -->
      <div class="tariff-card">
    <div class="tariff-header">
      <h3><i class="fas fa-file-invoice-dollar"></i> Текущий тариф</h3>
      <span class="tariff-name">{{ company.subscription.name || "Нет подписки" }}</span>
    </div>

    <div class="tariff-details">
      <p><strong>Лимит запросов в день:</strong> {{ company.subscription.daily_limit || "Неограничено" }}</p>
      <p>
        <strong>Использовано сегодня:</strong> 
        <span class="used-requests">
          {{ company.used_requests_today }} / {{ company.subscription.daily_limit }}
        </span>
      </p>

      <div class="progress-container">
        <div class="progress-bar" :style="{ width: usagePercentage + '%', background: progressColor }">
          {{ usagePercentage }}%
        </div>
      </div>

      
    </div>
  </div>
      <br>
      <!-- Использование лимита пользователями -->
      <div class="card">
    <h3>Использование лимита пользователями</h3>
    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th>ФИО</th>
          <th>Email</th>
          <th>Использовано запросов сегодня</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
            <td @click="toggleUserProtocols(user)" style="cursor: pointer;">
  {{ (selectedUser && selectedUser.id === user.id) ? "🔽" : "▶" }}
</td>
          <td @click="toggleUserProtocols(user)" style="cursor: pointer;">{{ user.full_name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.requests_today || 0 }}</td>
        </tr>
        <tr v-if="users.length === 0">
          <td colspan="4" class="text-center">Нет пользователей</td>
        </tr>
      </tbody>
    </table>

    <!-- Разворачиваемый список протоколов -->
    <div v-if="selectedUser" class="protocol-list">
      <h4>Протоколы пользователя: {{ selectedUser.full_name }}</h4>
      <table class="table">
        <thead>
          <tr>
            <th>Номер</th>
            <th>Дата</th>
            <th>Скачать</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="protocol in selectedUser.protocols" :key="protocol.id">
            <td>{{ protocol.num }}</td>
            <td>{{ protocol.date_time }}</td>
            <td>
              <button @click="downloadProtocol(protocol)" class="btn btn-success btn-sm">Скачать</button>
            </td>
          </tr>
          <tr v-if="selectedUser.protocols.length === 0">
            <td colspan="3" class="text-center">Нет протоколов</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
      <br>
      <!-- Управление пользователями -->
      <div class="card">
    <div class="card-header">
      <h3>Пользователи компании</h3>
      <button @click="addUser" class="btn btn-success">
        <i class="fas fa-user-plus"></i> Добавить пользователя
      </button>
    </div>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>ФИО</th>
            <th>Email</th>
            <th>Телефон</th>
            <th>Роль</th>
           
            <th class="text-center">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in users" :key="user.id">
            <td><input v-model="user.full_name" class="form-control input-sm" /></td>
            <td><input v-model="user.email" class="form-control input-sm" /></td>
            <td><input v-model="user.phone" class="form-control input-sm" /></td>
            <td>
              <select v-model="user.role" class="form-control input-sm">
                <option value="admin">Администратор</option>
                <option value="user">Пользователь</option>
              </select>
            </td>
           
            <td class="text-center" style="display: flex
;">
              <button @click="regeneratePassEmail(user)" style="margin-right: 5px;" class="btn btn-warning btn-sm" title="Сбросить ключ">
                <i class="fas fa-sync-alt"></i>
              </button>
              <button @click="saveUser(user)"  style="margin-right: 5px;" class="btn btn-primary btn-sm" title="Сохранить">
                <i class="fas fa-save"></i>
              </button>
              <button @click="confirmDelete(user)" class="btn btn-danger btn-sm" title="Удалить">
                <i class="fas fa-trash-alt"></i>
              </button>
            </td>
          </tr>
          <tr v-if="users.length === 0">
            <td colspan="6" class="text-center text-muted">Нет пользователей</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Модальное окно подтверждения удаления -->
    <div v-if="showDeleteModal" class="modal-overlay">
      <div class="modal-main" >
        <h4>Вы уверены, что хотите удалить пользователя?</h4>
        <p><strong>{{ userToDelete ? userToDelete.full_name : '' }}</strong> будет удалён без возможности восстановления.</p>
        <div class="modal-actions">
          <button @click="deleteUser" class="btn btn-danger">Удалить</button>
          <button @click="showDeleteModal = false" class="btn btn-secondary">Отмена</button>
        </div>
      </div>
    </div>
  </div>
  
      <!-- Разворачиваемый список протоколов -->
     
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        company: {
          id: null,
          name: "Название компании",
          subscription: {
            name: "Стандарт",
            daily_limit: 100
          },
          used_requests_today: 50
        },
        showDeleteModal: false,
      userToDelete: null,
        users: [
  {
    id: 1,
    full_name: "Иван Иванов",
    email: "ivan@example.com",
    phone: "+7 (999) 123-45-67",
    role: "user",
    pass_email: "ivan_123@vernimoe.pack",
    requests_today: 15,
    protocols: [
      { id: 1, number: "P-123", date: "2024-03-06", link: "/downloads/protocol1.pdf" },
      { id: 2, number: "P-124", date: "2024-03-07", link: "/downloads/protocol2.pdf" }
    ]
  },
  {
    id: 2,
    full_name: "Мария Петрова",
    email: "maria@example.com",
    phone: "+7 (999) 987-65-43",
    role: "admin",
    pass_email: "maria_456@vernimoe.pack",
    requests_today: 8,
    protocols: [
      { id: 3, number: "P-200", date: "2024-03-05", link: "/downloads/protocol3.pdf" }
    ]
  },
  {
    id: 3,
    full_name: "Алексей Смирнов",
    email: "alex@example.com",
    phone: "+7 (912) 345-67-89",
    role: "user",
    pass_email: "alex_789@vernimoe.pack",
    requests_today: 20,
    protocols: [
      { id: 4, number: "P-300", date: "2024-03-02", link: "/downloads/protocol4.pdf" },
      { id: 5, number: "P-301", date: "2024-03-03", link: "/downloads/protocol5.pdf" },
      { id: 6, number: "P-302", date: "2024-03-04", link: "/downloads/protocol6.pdf" }
    ]
  },
  {
    id: 4,
    full_name: "Ольга Кузнецова",
    email: "olga@example.com",
    phone: "+7 (913) 567-89-01",
    role: "user",
    pass_email: "olga_456@vernimoe.pack",
    requests_today: 3,
    protocols: []
  },
  {
    id: 5,
    full_name: "Дмитрий Соколов",
    email: "dmitry@example.com",
    phone: "+7 (921) 654-32-10",
    role: "admin",
    pass_email: "dmitry_111@vernimoe.pack",
    requests_today: 12,
    protocols: [
      { id: 7, number: "P-400", date: "2024-03-08", link: "/downloads/protocol7.pdf" },
      { id: 8, number: "P-401", date: "2024-03-09", link: "/downloads/protocol8.pdf" }
    ]
  }
],

        selectedUser: null
      };
    },
    computed: {
      usagePercentage() {
        if (!this.company.subscription.daily_limit) return 0;
        return Math.min(100, (this.company.used_requests_today / this.company.subscription.daily_limit) * 100);
      }
    },
    methods: {
      fetchCompanyData() {
        axios.get("https://api.webjustice.ru/api/company")
          .then(response => {
            this.company = response.data;
          })
          .catch(() => {
            console.warn("Не удалось получить данные компании. Используем заглушки.");
          });
  
        axios.get("https://api.webjustice.ru/api/company/users")
          .then(response => {
            this.users = response.data;
          })
          .catch(() => {
            console.warn("Не удалось получить список пользователей. Используем заглушки.");
          });
      },
      addUser() {
        this.users.push({
          id: null,
          full_name: "Новый пользователь",
          email: "new@example.com",
          phone: "+7 (000) 000-00-00",
          role: "user",
          pass_email: "new_user@vernimoe.pack",
          requests_today: 0,
          protocols: []
        });
      },
      saveUser(user) {
        axios.post("https://api.webjustice.ru/api/company/users/save", user).then(() => {
          alert("Пользователь сохранен");
          this.fetchCompanyData();
        }).catch(() => {
          alert("Ошибка при сохранении пользователя");
        });
      },
      confirmDelete(user) {
      this.userToDelete = user;
      this.showDeleteModal = true;
    },
    deleteUser() {
      this.users = this.users.filter(user => user.id !== this.userToDelete.id);
      this.showDeleteModal = false;
      this.userToDelete = null;
    },
      toggleUserProtocols(user) {
        this.selectedUser = (this.selectedUser && this.selectedUser.id === user.id) ? null : user;
      },
      regeneratePassEmail(user) {
        axios.post("https://api.webjustice.ru/api/company/users/generate-pass-key", { id: user.id })
          .then(response => {
            if (response.data.success) {
              user.pass_email = response.data.pass_email;
            } else {
              alert("Ошибка при генерации email");
            }
          })
          .catch(() => {
            alert("Ошибка сервера при генерации email");
          });
      },
      downloadProtocol(protocol) {
        window.open('https://www.shotapp.ru/protocol/'+protocol.num, '_blank').focus();
       
      }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
      this.fetchCompanyData();
    }
  };
  </script>
  <style scoped>
 .card {
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.table-responsive {
  overflow-x: auto;
}
.input-sm {
  padding: 5px;
  font-size: 14px;
}
.text-center {
  text-align: center;
}
.btn-sm i {
  font-size: 14px;
}
  .progress {
    height: 20px;
    background: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
  }
  .progress-bar {
    height: 100%;
    background: #28a745;
    text-align: center;
    color: white;
    font-weight: bold;
  }
  .text-center {
    text-align: center;
  }
  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex !important; /* Убедись, что flex! */
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Поверх всех элементов */
}

.modal-main {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  z-index: 1001; /* Выше оверлея */
}
.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.btn-secondary {
  background: #ccc;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}
.tariff-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  background: white;
  
}

/* Заголовок с иконкой */
.tariff-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.tariff-header i {
  color: #007bff;
  margin-right: 5px;
}

/* Название тарифа */
.tariff-name {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  padding: 5px 10px;
  background: #f1f1f1;
  border-radius: 4px;
}

/* Основные данные */
.tariff-details p {
  margin: 5px 0;
}

/* Использованные запросы */
.used-requests {
  font-weight: bold;
  color: #007bff;
}

/* Прогресс-бар */
.progress-container {
  width: 100%;
  height: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
}

.progress-bar {
  height: 100%;
  text-align: center;
  color: white;
  font-weight: bold;
  transition: width 0.5s ease-in-out;
}

/* Оставшиеся запросы */
.remaining-requests {
  font-size: 14px;
  color: #666;
  text-align: right;
  margin-top: 5px;
}
  </style>