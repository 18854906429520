<template>
	<div class="container">
		<b-breadcrumb :items="bc"/>
		<div class="row">
			<div class="col-lg-12">
				<div class="center-heading" style="margin-top:30px">
					<h1 class="section-title">Новое сообщение</h1>
				</div>
			</div>
		</div>
		<b-form @submit.prevent="onSubmit">
		<b-form-group id="InputGroup6"
						label="Тема сообщения:"
						label-for="Input6">
			<b-form-input id="Input6"
						type="text"
						required
						v-model="form.subj"
						placeholder="">
			</b-form-input>
		</b-form-group>
		<b-form-group  id="infoInputGroup" 
						label="Текст сообщения:"
						label-for="textTextarea">
			<!-- class="form-element"> -->
			<b-form-textarea id="textTextarea"
							v-model="form.msg"
							placeholder=""
							required
							style="margin-bottom:0"
							:rows="10"
							:max-rows="20">
			</b-form-textarea>
		</b-form-group>
		<div class="custom-file">
			<input @change="setFile()" type="file" class="custom-file-input" id="customFile">
			<label class="custom-file-label" for="customFile">{{file}}</label>
		</div>
		<div class="col-md text-center" style="padding:20px">
			<button type="submit" class="btn-secondary-box" :disabled="loading"><i v-if="loading" class="fas fa-spinner fa-pulse"></i> Отправить</button>
		</div>
		</b-form>
	</div>
</template>

<script>
import axios from 'axios';

export default {
    data () {
        return {
			bc: [
				{text: 'Список заявок', href: '/'}, 
				{text: 'Сообщения', href: '/messages'},
                {text: 'Новое сообщение', active: true}
            ],
            loading: false,
            form: {
				msg:'',
				subj:'',
			},
			file: null
        }
    },
	mounted() {
		this.$store.commit('SET_LOADER', false)
	}, 
    methods: {
       onSubmit () {
			let formData = new FormData();
			formData.append('file', document.getElementById('customFile').files[0])
			formData.append('msg', this.form.msg)
			formData.append('subj', this.form.subj)
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/messages`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(response => {
				this.$router.push('/messages')
            }).catch((error) => {
				if(error.response.status == 401){
					this.$router.push('/logout')
				}else{
					console.log(error)
				}
			});
		},
		setFile(){
			this.file = document.getElementById('customFile').files[0].name
		}
	},
	components: {
		
	},
}
</script>

<style scoped>
 .custom-file-input ~ .custom-file-label::after {
    content: "Файл";
}
</style>
