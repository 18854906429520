<template>
    <header id="header" class="header-area header-sticky">
        <div class="container">
            <b-navbar toggleable="sm" class="main-nav" id="nav">
                <b-navbar-toggle target="nav_collapse" class="menu-trigger"></b-navbar-toggle>
                <!-- ***** Logo Start ***** -->
                <b-navbar-brand href="/" class="logo">
                    <img src="/assets/images/logo.svg" class="float-left" alt="Logo" style="display: block;height:50px">
                    <div class="float-right" style="color: #5A6066;margin:11px 0 0 15px">
                        <p style="margin:0; line-height:.5"><strong style="font-size:14px">Личный кабинет</strong></p>
                        <span class="sys-name">Система нотариального заверения</span>
                    </div>
                </b-navbar-brand>
                <!-- ***** Logo End ***** -->
                <b-collapse is-nav id="nav_collapse"></b-collapse>	
                <!-- ***** Menu Start ***** -->
                <b-navbar-nav class="nav">
                    <b-nav-item-dropdown class="header-nav-item" v-for="(item, index) in menu" :key="index" :text="item.title" left>
                        <b-dropdown-item class="header-nav-dropdown-item" v-for="(sub, i) in item.sub" :key="i" :href="sub.href">
                            {{ sub.title }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                    
                    <b-nav-item-dropdown class="header-nav-item" v-for="(item, index) in filteredMenu2" :key="index" :text="item.title" left>
                        <b-dropdown-item class="header-nav-dropdown-item" v-for="(sub, i) in item.sub" :key="i" :href="sub.href">
                            {{ sub.title }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>						

                    <b-nav-item href="/profile">ПРОФИЛЬ</b-nav-item>
                    <b-nav-item href="/messages">
                        СООБЩЕНИЯ
                        <b-badge v-if="count != 0" pill variant="danger" class="bage-h">{{ count }}</b-badge>
                    </b-nav-item>
                    <b-nav-item href="/logout" class="btn-nav-line">ВЫЙТИ</b-nav-item>
                </b-navbar-nav>
            </b-navbar>

            <b-collapse id="nav_collapse">
                <ul class="nav-toggle" style="display: block;">
                    <b-nav-item-dropdown class="header-nav-item" v-for="(item, index) in menu" :key="index" :text="item.title" left>
                        <b-dropdown-item class="header-nav-dropdown-item" v-for="(sub, i) in item.sub" :key="i" :href="sub.href">
                            {{ sub.title }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>

                    <b-nav-item-dropdown class="header-nav-item" v-for="(item, index) in filteredMenu2" :key="index" :text="item.title" left>
                        <b-dropdown-item class="header-nav-dropdown-item" v-for="(sub, i) in item.sub" :key="i" :href="sub.href">
                            {{ sub.title }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>		

                    <li><a href="https://www.webjustice.ru" target="_blank">WEBJUSTICE.RU</a></li>
                    <li><a href="/new-request">НОВАЯ ЗАЯВКА</a></li>
                    <li><a href="/profile">ПРОФИЛЬ</a></li>
                    <li>
                        <a href="/messages">
                            СООБЩЕНИЯ
                            <b-badge v-if="count != 0" pill variant="danger" class="bage-h" style="margin-left:20px">{{ count }}</b-badge>
                        </a>
                    </li>
                    <li><a href="/logout" class="btn-nav-line">ВЫЙТИ</a></li>
                </ul>
            </b-collapse>
        </div>
    </header>
</template>



<script>
import axios from 'axios';

export default {
    data () {
        return {
            count: 0,
            timer: null,
            hasSubscription: false, // Проверка подписки
            menu: [
                {
                    title: 'НОТАРИАЛЬНЫЕ ПРОТОКОЛЫ',
                    sub: [
                        { title: 'НОВАЯ ЗАЯВКА', href: '/new-request' },
                        { title: 'МОИ ЗАЯВКИ', href: '/' },
                        { title: 'WEBJUSTICE.RU', href: 'https://www.webjustice.ru' },
                    ]
                }
            ],
            menu2: [
                {
                    title: 'АВТОФИКСАЦИЯ',
                    sub: [
                        { title: 'МОИ ПРОТОКОЛЫ', href: '/new-avtoreq' },
                        { title: 'ПОДПИСКА', href: '/sub-manage', requiresSubscription: true },
                        { title: 'МОИ ПАКЕТЫ', href: '/packs' },
                        { title: 'РЕФЕРАЛЬНАЯ ПРОГРАММА', href: '/ref' },
                        { title: 'SHOTAPP.RU', href: 'https://www.shotapp.ru' },
                    ]
                }
            ]
        };
    },
    computed: {
        // Фильтруем пункты меню, убирая "ПОДПИСКА" если подписки нет
        filteredMenu2() {
            return this.menu2.map(category => ({
                ...category,
                sub: category.sub.filter(sub => !sub.requiresSubscription || this.hasSubscription)
            }));
        }
    },
    mounted() {
        this.checkMsg();
        this.checkSubscription();
        this.timer = setInterval(this.checkMsg, 10000);
    },
    methods: {
        checkMsg() {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token;
            axios.get(this.$store.state.apiUrl + `/messages/check`)
                .then(response => {
                    this.count = response.data;
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.$router.push('/logout');
                        } else {
                            console.error(error);
                        }
                    }
                });
        },
        checkSubscription() {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token;
            axios.get(this.$store.state.apiUrl + "/api/company")
                .then(response => {
                    console.log(response);
                    this.hasSubscription = response.data.is_admin;
                })
                .catch(error => {
                    console.error("Ошибка проверки подписки:", error);
                });
        }
    },
    beforeDestroy() {
        clearInterval(this.timer);
    }
};
</script>

<style scoped>
	.bage-h{
		font-size: 14px;
		margin-left: 5px;
	}
</style>