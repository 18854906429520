<template>
	<div class="container">
		<b-breadcrumb :items="bc"/>
		<div class="row">
			<div class="col-lg-12">
				<div class="center-heading" style="margin-top:30px">
					<h1 class="section-title">Сообщения</h1>
				</div>
			</div>
		</div>	
		
	
		<b-table show-empty responsive hover
			:empty-text = "`Нет сообщений`"
			:fields="fields" 
			@row-clicked="rowClick"
			:items="items">
			<!-- <template slot="num" slot-scope="row">{{row.index + 1}}</template> -->
		</b-table>
				
			
	</div>
</template>

<script>
import axios from 'axios';

export default {
    data () {
        return {
			bc: [
                {text: 'Список заявок', href: '/'}, 
                {text: 'Сообщения', active: true}
            ],
            btn_visible: true,
            done_msg: false,
            form: {
                name:'',
                email:'',
                msg:''
			},
			items: [],
			fields: [
                { key: 'num', label: '№', tdClass: 'tdClass'},
				{ key: 'subj', label: 'Тема', tdClass: 'tdClass'},
				{ key: 'date', label: 'Дата', tdClass: 'tdClass'},
			],
        }
	},
	
	mounted() {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
		axios.get(this.$store.state.apiUrl+`/messages`)
        .then(response => {
			this.items = response.data 
			this.$store.commit('SET_LOADER', false)
        }).catch((error) => {
            if(error.response.status == 401){
				this.$router.push('/logout')
            }else{
				console.log(error)
            }
        });
	}, 
    methods: {
			rowClick(item, index) {
			this.$store.commit('SET_LOADER', true)
			this.$router.push({ path: `/message/${item.num}` })
		},
		
	},
	computed: {
       
    },
	components: {
		
	},
}
</script>

<style scoped>
  
</style>
