<template>
   <p></p>
</template>

<script>
import axios from 'axios';


export default {
    data () {
        return {
           
        }
    },
    created() {
        this.$store.commit('SET_LOADER', false)
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.post(this.$store.state.apiUrl+`/auth/logout`, {
            }).then(response => {
                localStorage.removeItem('t')
                this.$store.commit('SET_TOKEN', null)
                this.$router.push('/login')
            }).catch((error) => {
                    console.log(error)
            });
    },
    methods: {
      
	},
	components: {
		
	}
}
</script>

<style scoped>


</style>
