import index from './components/index.vue'
import login from './components/login.vue'
import logout from './components/logout.vue';
import claim from './components/claim.vue';
// import requestEmail from './components/request/email.vue';
// import requestSite from './components/request/site.vue';
import newRequest from './components/new_request.vue';
import newAutoRequest from './components/new_avtoreq.vue';
import subManage from './components/sub_manage.vue';
import packs from './components/packs.vue';
import ref from './components/ref.vue';
import tech from './components/tech.vue';
import profile from './components/profile.vue';
import customer from './components/customer.vue';
import customerUpdate from './components/customer_edit.vue';
import resetPassword from './components/reset_pass.vue'
import restorePassword from './components/restore_pass.vue'
import messages from './components/messages/index.vue';
import newMessage from './components/messages/new.vue';
import viewMessage from './components/messages/view.vue';
import imageMessage from './components/messages/image.vue';

export default [
    { path: '/', component: index, meta: {requiresAuth: true}},
    { path: '/restore', component: restorePassword, name:"restorePassword"},
    { path: '/reset-password/:token', component: resetPassword, name:"resetPassword"},
    { path: '/login', name: "login",component: login},
    { path: '/logout', name: "logout",component: logout, meta: {requiresAuth: true}},
    { path: '/profile', name: "profile",component: profile, meta: {requiresAuth: true}},
    { path: '/profile/customer', name: "customer",component: customer, meta: {requiresAuth: true}},
    { path: '/profile/customer/:id', name: "customerUpdate",component: customerUpdate, meta: {requiresAuth: true}},
    { path: '/claim/:num', component: claim, name: "claim", meta: {requiresAuth: true}},
    { path: '/claim/:num/tech', component: tech, name: "tech", meta: {requiresAuth: true}},
    // { path: '/request/site', component: requestSite, name: "site", meta: {requiresAuth: true}},
    // { path: '/request/email', component: requestEmail, name: "email", meta: {requiresAuth: true}},
    { path: '/new-request', component: newRequest, name: "newRequest", meta: {requiresAuth: true}},
    { path: '/new-avtoreq', component: newAutoRequest, name: "newAutoRequest", meta: {requiresAuth: true}},
    { path: '/sub-manage', component: subManage, name: "subManage", meta: {requiresAuth: true}},
   
    { path: '/packs', component: packs, name: "packs", meta: {requiresAuth: true}},
    { path: '/ref', component: ref, name: "ref", meta: {requiresAuth: true}},
    { path: '/messages*', name: "messages",component: messages, meta: {requiresAuth: true}},
    { path: '/message/new', name: "newMessage",component: newMessage, meta: {requiresAuth: true}},
    { path: '/message/:num', name: "viewMessage",component: viewMessage, meta: {requiresAuth: true}},
    { path: '/message/:num/image/:file', name: "imageMessage",component: imageMessage, meta: {requiresAuth: true}},
    { path: '*', component: index, meta: {requiresAuth: true}}
]