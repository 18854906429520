<template>
    <div class="text-center">
        <img class="mb-4" src="/assets/images/logo.svg" alt="" width="120" height="120">
        <div v-if="passwordChanged">
            <h1 class="h3 mb-3 font-weight-normal">Пароль успешно изменён!</h1>
            <br>
            <b-link to="/login">Войти в Личный кабинет</b-link>
        </div>
        <form v-if="!passwordChanged" class="form-signin" @submit.prevent="onSubmit">
            <h1 class="h3 mb-3 font-weight-normal">Новый пароль</h1>
            <label for="inputPhone" class="sr-only">Пароль</label>
            
                <input type="password" id="inputPassword" class="form-control" placeholder="Пароль" v-model="form.password" required="">
           
            <label for="inputPassword2" class="sr-only">Повторите пароль</label>
          
                <input  type="password" id="inputPassword2" class="form-control" placeholder="Повторите пароль" v-model="password2" required="">
               
            <br>
            <p v-if="errorMsg != ''" class="text-danger">{{errorMsg}}</p>
            <button class="btn-secondary-box" type="submit" style="padding: 0 50px" :disable="btnBlock"><i v-if="btnBlock" class="fas fa-spinner fa-pulse"></i> Отправить</button>
        
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import AwesomeMask from 'awesome-mask'

export default {
    data () {
        return {
            form: {
                password: '',
                token: this.$route.params.token
            },
            password2: '',
            errorMsg: '',
            btnBlock: false,
            passwordChanged: false
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
    },
     directives: {
        'mask': AwesomeMask
    },
    methods: {
       onSubmit (evt) {
            if(this.form.password != this.password2){
               this.errorMsg = 'Пароли не совпадают'
               return false
            }
            if(this.form.password.length < 6){
               this.errorMsg = 'Пароль должен содержать не менее 6 символов'
               return false
            }
            this.btnBlock = true
            axios.post(this.$store.state.apiUrl+`/auth/reset`, {
                body: this.form
            }).then(response => {
                this.btnBlock = false
                this.passwordChanged = true
            }).catch(error => {
                if(error.response.status == 401){
                    this.errorMsg = 'Ключь для смены пароля устарел'
                    this.btnBlock = false
                    return false
                }
                console.log(error)
                this.errorMsg = 'Произошла ошибка'
                this.btnBlock = false
                
            });
        },
	},
	components: {
		
	}
}
</script>

<style scoped>
   input:invalid {
    box-shadow: none;
}
   .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: 0 auto;
    }

    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }
    .form-signin input[id="inputPassword"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .form-signin input[id="inputPassword2"] {
        /* margin-bottom: 10px; */
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
   

    .phone .input-group-text {
        border-bottom-left-radius: 0;
         margin-bottom: -1px;
    }

    .lock .input-group-text {
        border-top-left-radius: 0;
        padding-right: 14px;
        padding-left: 14px;
    }

</style>
