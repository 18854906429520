<template>
    <div class="container">
        <div>			
						<b-form @submit="onSubmit">
							<div class="contact-form">
								<h3 class="section-title" style="align:centre">Заказать пакеты</h3>
                                
                                     <b-card-group deck>
                                         <div class="flex" for="one">
                                            <b-card
    img-src="assets/images/pack1.jpg"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 21rem;"
    img-stle
    class="mb-2"
  >
  <b-form-radio required name="pickable" type="radio" id="one" class="bttn" value="1" size="lg" v-model="form.picked"></b-form-radio>
                                        <label for="one"><h2>Пакет 1</h2>
                     <h5>                   
                                          Количество протоколов: 20 <br>
    Стоимость: 10000 ₽
                         </h5>               
                                        </label>


     
  </b-card>  
                                         </div>
                     
  <div class="flex">
             <b-card
    
    img-src="assets/images/pack2.jpg"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 21rem;"
    class="mb-2"
  >
   
<b-form-radio required name="pickable" type="radio" id="two" class="bttn" value="2" size="lg" v-model="form.picked"></b-form-radio>
                                        <label for="two" ><h2>Пакет 2</h2>
                     <h5>                   
                                          Количество протоколов: 50 <br>
    Стоимость: 20000 ₽
                         </h5>               
                                        </label>
     
  </b-card>                                   
                                         </div>

   <div class="flex">
                                <b-card
   
    img-src="assets/images/pack3.jpg"
    img-alt="Image"
    img-top
    tag="article"
    style="max-width: 21rem;"
    class="mb-2"
  >
    <b-form-radio required name="pickable" type="radio" id="three" class="bttn" value="3" size="lg" v-model="form.picked"></b-form-radio>
                                        <label for="three"><h2>Пакет 3</h2>
                     <h5>                   
                                          Количество протоколов: 100 <br>
    Стоимость: 30000 ₽
                         </h5>               
                                        </label>
  </b-card>              
                                         </div>
  
  </b-card-group>	
  <div class="col-sm-12">
    <div class="row">
        <div class="col-md-6">
            <b-form-radio required name="pickable2" type="radio" id="onep" class="bttnn" value="1" size="lg" v-model="form.pay_type">Оплатить картой онлайн</b-form-radio>
        </div>
        <div class="col-md-6">
            <b-form-radio required name="pickable2" type="radio" id="twop" class="bttnn" value="2" size="lg" v-model="form.pay_type">Оплатить по счёту</b-form-radio>				 
        </div>
    </div>
  </div>					
 
                                <div class="col-md-12">
								<div class="form-element" style="margin-left: -15px; margin-right: 15px;">
									<button v-if="btn_visible" class="btn btn-primary confirm"  style="margin-top:15px; width: 100%" aria-pressed="true">Заказать</button>
									<div v-if="done_msg" class="text-center done-msg">Сообщение отправленно</div>
								</div>
							</div>
                            </div>
						</b-form>
			</div>
        <div class="center-heading" style="margin-top:30px">
            <h1 class="section-title">Ваши пакеты</h1>
        </div>
                <b-table show-empty 
                    hover 
                    responsive="md"
                    :empty-text = "`Пусто`"
                    :fields="fields1" 
                    :items="filter1"
                    :current-page="currentPage1"
                    :per-page="perPage"
                   
                   :tbody-tr-class="rowClass">
                   <template v-slot:cell(action)="data">
        <span v-html="data.value"></span>
    </template>
                </b-table>
                <b-row>
                    <b-col md="6" class="my-1">
                        <b-pagination :total-rows="totalRows1" :per-page="perPage" v-model="currentPage1">
                        </b-pagination>
                    </b-col>
                </b-row>
        <br>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    data () {
        return {
            btn_visible: true,
            done_msg: false,
            fields1: [
                { key: 'fullNum', label: 'Номер', sortable: true },
                { key: 'date', label: 'Дата регистрации', sortable: true },
                { key: 'status', label: 'Статус', sortable: true },
                { key: 'cost', label: 'Стоимость', sortable: true },
                { key: 'count', label: 'Остаток', sortable: true },
                { key: 'action', label: 'Оплата', sortable: true },
                
            ],
            form: {
                picked:'',
                pay_type:'',
            },
            fields2: [
                { key: 'fullNum', label: 'Номер', sortable: true },
                { key: 'date', label: 'Дата', sortable: true },
                { key: 'status', label: 'Статус', sortable: true },
                { key: 'cost', label: 'Стоимость', sortable: true },
                { key: 'action', label: 'Действие', sortable: true },
            ],
            items: [],
            totalRows1: 0,
            totalRows2: 0,
            currentPage1: 1,
            currentPage2: 1,
            perPage: 10,
            filter: ['Отменён', 'Оплачен','Ошибка']
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/cards`)
        .then(response => {
            this.items = response.data
            this.totalRows = response.data.length;
           const rows1=this.items.filter(f => (!this.filter.includes(f.status)));
             this.totalRows1 =  rows1.length
           const  rows2=this.items.filter(f => (this.filter.includes(f.status)));
            this.totalRows2 = rows2.length
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log()
            }
        });
    },
    components: {
    },
    
    computed: {
        
        filter1(){
            const f = this.items.filter(f => (!this.filter.includes(f.status)));
           
         // this.totalRows1 = f.length
            return f
        },
        filter2(){
            const f = this.items.filter(f => this.filter.includes(f.status) && !this.filter.includes(f.cost))
        //    this.totalRows2 = f.length
            return  f
        },
       
    },
    methods: {
        onSubmit (evt) {
            evt.preventDefault();
             console.log(JSON.stringify(this.form));
             axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/sp/points_lk`, {
                body: this.form
            }).then(response => {
                console.log(response);
				this.btn_visible = false;
				this.done_msg = true;

				setTimeout(() => {
					this.btn_visible = true;
					this.done_msg = false;
                    location.reload();
				}, 3000);
            });
        },
        rowClick(item, ) {
            this.$store.commit('SET_LOADER', true)
           this.$router.push({ path: `/claim/${item.num}` })
        },
        rowClass(item) {
        return 'table-'+item.row_color
      },
        action(row){
           
            return row.item.action
        }
    }
}
</script>

<style scoped>
   
</style>