<template>
	<div class="container">
		<b-breadcrumb :items="bc"/>
		<div class="row">
			<div class="col-lg-12">
				<div class="center-heading" style="margin-top:30px">
					<h1 class="section-title">Сообщение № {{this.$route.params.num}}</h1>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<b-list-group style="margin-top:20px">
					<b-list-group-item variant="secondary">
						{{content.subj}}
					</b-list-group-item>
					<b-list-group-item v-for="(item) in content.items" :key="item.index">
						<span class="msg-from">{{item.from}}</span>
						<span class="msg-date float-right">{{item.date}}</span>
						
						<div class="msg-text mt-3 mb-3">{{item.text}}</div>
						
						<b-button v-if="item.file != ''" @click="$router.push('/message/'+$route.params.num+'/image/'+item.file)" target="_blank" variant="outline-secondary"><i class="fa fa-paperclip mr-3"></i>{{item.file}}</b-button>
					</b-list-group-item> 
					<b-list-group-item v-if="content.status == 0" class="text-center">
						<b-button @click="done()" variant="outline-success">
							Спасибо, мой вопрос решён
						</b-button>
					</b-list-group-item>
					<b-list-group-item v-if="content.status == 0">
						<b-input-group>
							<b-input-group-prepend>
								<b-button @click="$refs.file.click()" variant="outline-secondary"><i class="fa fa-paperclip"></i></b-button>
							</b-input-group-prepend>
							<b-form-input v-model="text" type="text" placeholder="" />
							<b-input-group-append>
								<b-button @click.prevent="sendMsg()" variant="primary">Отправить</b-button>
							</b-input-group-append>
						</b-input-group>
						<div v-if="file != null" class="mt-3 mb-3">{{file}}</div>
					</b-list-group-item>
					<b-list-group-item v-if="content.status == 1" class="text-center" variant="success">
							Ваш вопрос решён
					</b-list-group-item>
				</b-list-group>
			</div>
		</div>
		<input id="msgFile" @change="setFile()" type="file" ref="file" style="display: none">
		<br>
	</div>
</template>

<script>
import axios from 'axios';

export default {
    data () {
        return {
			bc: [
				{text: 'Список заявок', href: '/'}, 
				{text: 'Сообщения', href: '/messages'},
                {text: 'Сообщение №'+this.$route.params.num, active: true}
            ],
			content: {
				from:'',
			},
			text: '',
			file: null
        }
    },
	mounted() {
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
		axios.get(this.$store.state.apiUrl+`/message/` + this.$route.params.num)
        .then(response => {
			this.content = response.data 
			this.$store.commit('SET_LOADER', false)
        }).catch((error) => {
            if(error.response.status == 401){
				this.$router.push('/logout')
            }else{
				console.log(error)
            }
        });
	}, 
    methods: {
		done(){
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
			axios.post(this.$store.state.apiUrl+`/message/` + this.$route.params.num, {
				body: 'done'
			}).then(response => {
				this.$router.push('/messages')
            }).catch((error) => {
				if(error.response.status == 401){
					this.$router.push('/logout')
				}else{
					console.log(error)
				}
			});
		},
		download(id, file) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios({
                url: this.$store.state.apiUrl+`/message/download/`+ id,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((error) => {
                
                console.log(error);
            });
            
		},
		sendMsg () {
			let formData = new FormData();
			let id = this.$route.params.num
			formData.append('file', document.getElementById('msgFile').files[0])
			formData.append('msg', this.text)
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/message/`+this.$route.params.num, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(response => {
				location.reload()
            }).catch((error) => {
				if(error.response.status == 401){
					this.$router.push('/logout')
				}else{
					console.log(error)
				}
			});
		},
		setFile(){
			this.file = document.getElementById('msgFile').files[0].name
		},
		imgLink(file){
			return this.$store.state.apiUrl+`/storage/msgs/`+ file
		}
	},
	components: {
		
	},
}
</script>

<style scoped>
   .msg-text {
		font-size: 14px;
   }
   .msg-date {
		font-size: 13px;
   }
   .msg-from {
		font-size: 14px;
		font-weight: 600;
   }
</style>
