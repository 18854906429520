<template>
    <div class="container">
        
        <div class="center-heading" style="margin-top:30px">
            <h1 class="section-title">Ваши протоколы автофиксации</h1>
        </div>
       <tabs >
			<tab name="Текущие">
              <b-col lg="12" class="my-1">
        <b-form-group
         
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filterSearch"
              type="search"
              placeholder="Введите для поиска"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
                <b-table show-empty 
                    hover 
                    responsive="md"
                    :empty-text = "`Пусто`"
                    :fields="fields1" 
                    :items="filter1"
                    :filter="filterSearch"
                    :current-page="currentPage1"
                    :per-page="perPage"
                   
                    :tbody-tr-class="`tr-row`">
                    
                    
                    <template v-slot:cell(action)="data">
        <span v-html="data.value"></span>
    </template>
    <template v-slot:cell(pass)="data">
        <b-button
          :variant="data.value ? 'danger' : 'success'"
          @click="toggleLock(data.item)"
        >
          <i :class="data.value ? 'fa fa-lock' : 'fa fa-unlock'"></i>
        </b-button>
      </template>
                </b-table>
                <b-row>
                    <b-col md="6" class="my-1">
                        <b-pagination :total-rows="totalRows1" :per-page="perPage" v-model="currentPage1">
                        </b-pagination>
                    </b-col>
                </b-row>
            </tab>
			<tab name="Завершенные">
                <b-col lg="12" class="my-1">
        <b-form-group
         
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filterSearch2"
              type="search"
              placeholder="Введите для поиска"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Очистить</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
                 <b-table show-empty
                    hover 
                    responsive="md"
                    :empty-text = "`Пусто`"
                    :fields="fields2" 
                    :items="filter2"
                    :filter="filterSearch2"
                    :current-page="currentPage2"
                    :per-page="perPage"
                    
                    :tbody-tr-class="`tr-row`">
                    <template v-slot:cell(action)="data">
        <span v-html="data.value"></span>
    </template>
    <template v-slot:cell(pass)="data">
        <b-button
          :variant="data.value ? 'danger' : 'success'"
          @click="toggleLock(data.item)"
        >
          <i :class="data.value ? 'fa fa-lock' : 'fa fa-unlock'"></i>
        </b-button>
      </template>
                </b-table>
                <b-row>
                    <b-col md="6" class="pagination">
                        <b-pagination :total-rows="totalRows2" :per-page="perPage" v-model="currentPage2">
                        </b-pagination>
                    </b-col>
                </b-row>
            </tab>
        </tabs>
        <br>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    data () {
        return {
            filterSearch:'',
             filterSearch2:'',
            fields1: [
                { key: 'fullNum', label: 'Номер', sortable: true },
                { key: 'date', label: 'Дата', sortable: true },
                { key: 'status', label: 'Статус', sortable: true },
                { key: 'cost', label: 'Стоимость', sortable: true },
                { key: 'action', label: 'Действие', sortable: true },
                { key: 'pass', label: 'доступ', sortable: true }
                
            ],
            fields2: [
                { key: 'fullNum', label: 'Номер', sortable: true },
                { key: 'date', label: 'Дата', sortable: true },
                { key: 'status', label: 'Статус', sortable: true },
                { key: 'cost', label: 'Стоимость', sortable: true },
                { key: 'action', label: 'Действие', sortable: true },
                 { key: 'pass', label: 'доступ', sortable: true }
            ],
            items: [],
            totalRows1: 0,
            totalRows2: 0,
            currentPage1: 1,
            currentPage2: 1,
            perPage: 10,
            filter: ['Отменён', 'Оплачен','Ошибка']
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/autoclaims`)
        .then(response => {
            this.items = response.data
            this.totalRows = response.data.length;
           const rows1=this.items.filter(f => (!this.filter.includes(f.status)));
             this.totalRows1 =  rows1.length
           const  rows2=this.items.filter(f => (this.filter.includes(f.status)));
            this.totalRows2 = rows2.length
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log()
            }
        });
    },
    components: {
    },
    
    computed: {
        filter1(){
            const f = this.items.filter(f => (!this.filter.includes(f.status)));
           
         // this.totalRows1 = f.length
            return f
        },
        filter2(){
            const f = this.items.filter(f => this.filter.includes(f.status) && !this.filter.includes(f.cost))
        //    this.totalRows2 = f.length
            return  f
        },
       
    },
    methods: {
          toggleLock(item) {
      // Предполагая, что у вас есть поле isLocked в объекте данных
      item.pass = !item.pass;

      // Здесь вы можете добавить логику для отправки GET-запроса
      // используя, например, Axios или Fetch
      this.sendGetRequest(item);
    },
    sendGetRequest(item) {
      // Здесь реализуйте отправку GET-запроса с использованием
      // вашей библиотеки для HTTP-запросов
      // Например, с использованием Axios:
       axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/autopass/`+item.fullNum)
        .then(response => {
           item.pass=response.data
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log()
            }
        });
    },

        rowClick(item, ) {
            this.$store.commit('SET_LOADER', true)
           this.$router.push({ path: `/claim/${item.num}` })
        },
        action(row){
           
            return row.item.action
        }
    }
}
</script>

<style scoped>
   
</style>