import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        apiUrl:'https://api.webjustice.ru',
        // apiUrl:'http://api.wj.lan',
        loader: true,
        token: localStorage.getItem('t') || null
    },
    getters: {
        loader(state) {
            return state.loader
        },
        loggedIn(state) {
            return state.token != null
        },
        token(state) {
            return state.token
        },
        tokenData (state) {
            return state.token ? JSON.parse(atob(state.token.split('.')[1])) : null
        },
        tokenUid(state, getters){
            return getters.tokenData ? getters.tokenData.uid : null
        },
       
    },
    mutations: {
        SET_LOADER(state, action) {
            state.loader = action
        },
        SET_TOKEN (state, token) {
            state.token = token
        },
       
    },
    // actions: {
    //     setLoader({ commit }, loader) {
    //         commit('setState', loader)
    //     }
    // }
})

export default store