<template>
    <div class="container">
        <b-breadcrumb :items="bc"/>
        <div class="center-heading" style="margin-top:30px">
            <h1 class="section-title">Профиль пользователя</h1>
        </div>
        
       <tabs >
			<tab name="Мои данные">
                <b-form @submit.prevent="submitChanges" style="padding:20px">
                    <b-form-group id="InputGroup1"
                                    label="Ф.И.О:"
                                    label-for="Input1">
                        <b-form-input id="Input1"
                                    type="text"
                                    v-model="form.name"
                                    required
                                    placeholder="Введите ФИО пользователя">
                        </b-form-input>
                    </b-form-group>
                  
                   <div class="row">
                       <div :class="isRegions ? 'col-md-4' : 'col-md-6'">
                            <b-form-group id="InputGroup7" 
                                            label="Страна:"
                                            label-for="Input7">
                                <b-form-select id="Input7"
                                            :options="countriesList"
                                            required
                                            @change="getRegions"
                                            v-model="form.country">
                                </b-form-select>
                            </b-form-group>
                       </div>
                       <div v-if="isRegions" class="col-md-4">
                            <b-form-group id="InputGroup4" 
                                            label="Регион:"
                                            label-for="Input4">
                                <b-form-select id="Input4"
                                            :options="content.regions"
                                            required
                                            @change="getCitys"
                                            v-model="form.region">
                                </b-form-select>
                            </b-form-group>
                       </div>
                       <div :class="isRegions ? 'col-md-4' : 'col-md-6'">
                            <b-form-group id="InputGroup5"
                                            label="Город:"
                                            label-for="Input5">
                                <b-form-select id="Input5"
                                            :options="content.citys"
                                            required
                                            v-model="form.city">
                                </b-form-select>
                            </b-form-group>
                       </div>
                   </div>
                   
                   <div class="row">
                       <div class="col-md-6">
                            <b-form-group id="InputGroup3"
                                            label="Номер телефона:"
                                            label-for="Input3">
                                <b-input-group :prepend="phoneCode">
                                    <b-form-input id="Input3"
                                                type="text"
                                                v-model="form.phone"
                                                required
                                                v-mask="'(999)-999-99-99'"
                                                placeholder="(900)-123-45-67">
                                    </b-form-input>
                                </b-input-group>
                            </b-form-group>
                       </div>
                       <div class="col-md-6">
                             <b-form-group id="InputGroup2"
                                            label="Email адрес"
                                            label-for="Input2">
                                <b-form-input id="Input2"
                                            type="email"
                                            v-model="form.email"
                                            required
                                            placeholder="Введите Email адрес">
                                </b-form-input>
                            </b-form-group>
                       </div>
                   </div>

                    

                    <b-form-group id="InputGroup6"
                                    label="Компания:"
                                    label-for="Input6">
                        <b-form-input id="Input6"
                                    type="text"
                                    v-model="form.company"
                                    placeholder="ООО 'Ваша компания'">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="InputGroup9" 
                                            label="Доступ к автопротолам:"
                                            label-for="Input91">
                                <b-form-select id="Input91"
                                            :options="['Открыт','Пин-код']"
                                            required
                                            v-model="form.pass">
                                </b-form-select>
                            </b-form-group>
                    <br>
                    
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button type="submit" class="btn-secondary-box">Сохранить</button>
                        </div>
                    </div>
                    
                </b-form>
            </tab>
            <tab name="Адрес доставки">
                 <b-form @submit.prevent="submitDelivery" style="padding:20px">
                    <b-form-group id="InputGroup1"
                                    label="Адрес"
                                    label-for="Input1">
                        <b-form-input id="Input1"
                                    type="text"
                                    v-model="formDelivery.address"
                                    required
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="InputGroup2"
                                    label="Получатель"
                                    label-for="Input2">
                        <b-form-input id="Input2"
                                    type="text"
                                    v-model="formDelivery.receiver"
                                    required
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>
                 
                             <b-form-group id="InputGroup3"
                                            label="Номер телефона:"
                                            label-for="Input3">
                                <b-input-group :prepend="phoneCodeD">
                                    <b-form-input id="Input3"
                                                type="text"
                                                v-model="formDelivery.phone"
                                                required
                                                placeholder="+7(900)-123-45-67">
                                    </b-form-input>
                                </b-input-group>
                            </b-form-group>
                 
                   
                  
                    <b-form-group id="InputGroup6"
                                    label="Инфо:"
                                    label-for="Input6">
                        <b-form-input id="Input6"
                                    type="text"
                                    v-model="formDelivery.info"
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button type="submit" class="btn-secondary-box">Сохранить</button>
                        </div>
                    </div>
                    
                </b-form>
            </tab>
			<tab name="Заказчики">
                <a class="float-right btn btn-sm btn-success" style="margin:10px" href="/profile/customer">Создать заказчика</a>
                <b-table show-empty
                    hover 
                    :empty-text = "`Пусто`"
                    :fields="fields" 
                    :items="customers"
                    :current-page="currentPage"
                    :per-page="perPage"
                    @row-clicked="rowClick" 
                    :tbody-tr-class="`tr-row`">
                    <!-- <template slot="action" slot-scope="row">{{ action(row) }}</template> -->
                </b-table>
                 <b-row>
                    <b-col md="6" class="my-1">
                        <b-pagination :total-rows="totalRows" :per-page="perPage" v-model="currentPage">
                        </b-pagination>
                    </b-col>
                </b-row>
            </tab>
        </tabs>
        <br>
    </div>
</template>

<script>
import axios from 'axios';
import AwesomeMask from 'awesome-mask'

export default {
    data () {
        return {
            bc: [
                {text: 'Список заявок', href: '/'}, 
                {text: 'Профиль пользователя', active: true}
            ],
            fields: [
                { key: 'name', label: 'Наименование', sortable: true },
                { key: 'address', label: 'Адрес', sortable: true },
            ],
            form:{
                name: '',
                email: '',
                phone: '',
                country: 'ru',
                region: '',
                city: '',
                company:'',
            },
            formDelivery:{
                address:'',
                receiver: '',
                phone: '',
                info: ''
            },
            content: {
                countries:[],
                regions: [],
                citys: [],
            },
            customers: [],
            // totalRows: 0,
            currentPage: 1,
            perPage: 10,
            customer_tab: false,
            profile_tab: true,
            isRegions: true
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/profile`)
        .then(response => {
            // console.log(response.data)
            this.form = response.data.form
            this.content = response.data.content
            this.customers = response.data.customers
            this.formDelivery = response.data.delivery
            if(this.form.region == 0){
                this.isRegions = false
            }
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log(error)
            }
        });
    },
     directives: {
        'mask': AwesomeMask
    },
    components: {

    },
    computed: {
        totalRows(){
           return this.customers.length
        },
        countriesList(){
            let arr = new Object()
            this.content.countries.forEach(el => {
                arr[el.code] = el.name
            });
            return arr
        },
        phoneCode(){
            let arr = this.content.countries.filter(el => {return el.code == this.form.country})
            if(arr.length > 0){
                return arr[0].phone_code
            }
            return '?'
        }
    },
    methods: {
        rowClick(item, index) {
            this.$store.commit('SET_LOADER', true)
           this.$router.push({ path: `/profile/customer/${item.id}` })
        },
        submitChanges(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/profile`, {
                    body: this.form
                }).then(response => {
                    // this.form = response.data.form
                    // this.content = response.data.content
                }).catch(error => {
                    console.log(error)
                });
        },
         submitDelivery(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/profile/delivery`, {
                    body: this.formDelivery
                }).then(response => {
                    // this.form = response.data.form
                    // this.content = response.data.content
                }).catch(error => {
                    console.log(error)
                });
        },
        getRegions(){
            if(this.form.country == 'ru' || this.form.country == 'kz' || this.form.country == 'by'){
                this.isRegions = true
                  setTimeout(() => {
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
                    axios.get(this.$store.state.apiUrl+`/profile/regions/`+this.form.country)
                    .then(response => {
                        this.content.regions = response.data.content.regions;
                        this.content.citys = []
                        this.form.region = ''
                        this.form.city = ''
                    })
                    .catch(e => {
                        if(e.response.status == 401){
                            this.$router.push('/logout')
                        }else{
                            console.log(e)
                        }
                    });
                }, 100);
            }else{
                this.form.region = null
                this.isRegions = false
                this.getCitys()
              
            }
        },
        getCitys(){
            setTimeout(() => {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
                axios.get(this.$store.state.apiUrl+`/profile/citys?country=`+this.form.country+`&region=`+this.form.region)
                .then(response => {
                    this.content.citys = response.data.content.citys
                }).catch((error) => {
                    if(error.response.status == 401){
                        this.$router.push('/logout')
                    }else{
                        console.log(error)
                    }
                });
            }, 100);
        }
    }
}
</script>

<style scoped>
  ::placeholder { 
  opacity: 0.4; 
}
</style>