<template>
    <footer id="footer">
		<div class="container">
			<div class="row under-footer">
				<div class="col-md-12 text-center">
					<p class="copyright">© 2013 - 2025 Интернет-правосудие</p>
				</div>
				
			</div>
		</div>
	</footer>
	
</template>

<script>

export default {
    data () {
        return {
          items:[]
        }
    },
	created() {
       
    },
    methods: {
     
    }
}
</script>

<style scoped>
	.under-footer{
		color: #3B566E;
		font-size: 14px;
		padding-top: 20px;
	}

	#footer {
		/* position: absolute; */
		bottom: 0;
		width: 100%;
		height: 60px;
		/* line-height: 60px; */
		background-color: #f5f5f5;
	}
</style>