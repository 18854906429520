<template>
    <div class="container">
       <br> 
            <b-form @submit.prevent="onSubmit">
			<!-- ***** Features Items Start ***** -->
			<div class="row">
                <div class="col-md-6">
                    <b-form-group id="urlInputGroup"
                                    label="Email адрес"
                                    label-for="emailInput"
                                    class="form-element">
                        <b-form-input id="emailInput"
                                    type="email"
                                    v-model="form.email"
                                    required
                                    placeholder="example@domain.ru">
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="col-md-6"> 
                    <b-form-group id="info"
                            label="Предмет спора (цель заверения)"
                            label-for="issue"
                            class="form-element">
                        <b-form-select v-model="form.issue" :options="issues"></b-form-select>
                    </b-form-group>
                </div>
			</div>
            <div class="row">
                <div class="col-md-12">
                    <b-form-group id="infoInputGroup"
                                    class="form-element"
                                    style="margin-bottom:0">
                        <b-form-textarea id="infoTextarea"
                                        v-model="form.info"
                                        placeholder="Опишите действия которые необходимо произвести"
                                        :rows="3"
                                        :max-rows="8">
                        </b-form-textarea>
                    </b-form-group>
                </div>
			</div>
           
            
            <br>
            <div class="row">
                <div class="col-md-12 text-center">
                    <button type="submit" class="btn-secondary-box" :disabled="formSend"><i v-if="formSend" class="fas fa-spinner fa-pulse"></i> Создать заявку</button>
                    <p v-if="errorMsg != ''" class="text-danger" style="margin:10px 0 0 0">{{errorMsg}}</p>
                    <p style="margin-top:30px;font-size:10px" class="text-muted">Нажимая кнопку «Отправить заявку» я принимаю 
                        <a href="https://webjustice.ru/polzovatelskoe-soglasenie" target="_blank">Пользовательское соглашение</a> и даю согласие на обработку персональных данных на условиях и в целях, 
                        определенных<br> <a href="https://webjustice.ru/politika-konfidencialnosti" target="_blank">Политикой конфиденциальности</a></p>
                </div>
            </div>
            
            </b-form>
		</div>
    
</template>

<script>
import axios from 'axios';

export default {
    data () {
        return {
            bc: [
                {text: 'Список заявок', href: '/'}, 
                {text: 'Создание заявки', active: true}
            ],
            form: {
                type: 'email',
                email: '',
                info: '',
                reg: {
                    name: '',
                    phone: '',
                    email: '',
                    company: ''
                }
            },
            claim_num: '',
            formSend: false,
            errorMsg: '',
        }
    },
     props: {
        tariffs: {},
        issues: {},
    },
    mounted(){
        this.$store.commit('SET_LOADER', false)
    },
    methods: {
        onSubmit () {
            this.formSend = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/claim/new`, {
                body: this.form
            }).then(response => {
                this.claim_num = response.data.num;
                this.formSend = false;
                this.$router.push('/')
            }).catch((error) => {
                // eslint-disable-next-line
                console.log(error);
                this.formSend = false;
                this.errorMsg = 'Произошла ошибка'
            });
        },
	},
	components: {
		
	}
}
</script>

<style scoped>

</style>