<template>
    <div class="container">
        
        <div class="center-heading" style="margin-top:30px">
            <h1 class="section-title">Ваши заявки</h1>
        </div>
       <tabs >
			<tab name="Текущие">
           
                <b-table show-empty 
                    hover 
                    responsive="md"
                    :empty-text = "`Пусто`"
                    :fields="fields1" 
                    :items="filter1"
                    :current-page="currentPage1"
                    :per-page="perPage"
                    @row-clicked="rowClick" 
                    :tbody-tr-class="`tr-row`">
                    <template slot="action" slot-scope="row">{{ action(row) }}</template>
                </b-table>
                <b-row>
                    <b-col md="6" class="my-1">
                        <b-pagination :total-rows="totalRows1" :per-page="perPage" v-model="currentPage1">
                        </b-pagination>
                    </b-col>
                </b-row>
            </tab>
			<tab name="Завершенные">
            
                 <b-table show-empty
                    hover 
                    responsive="md"
                    :empty-text = "`Пусто`"
                    :fields="fields2" 
                    :items="filter2"
                    :current-page="currentPage2"
                    :per-page="perPage"
                    @row-clicked="rowClick" 
                    :tbody-tr-class="`tr-row`">
                </b-table>
                <b-row>
                    <b-col md="6" class="pagination">
                        <b-pagination :total-rows="totalRows2" :per-page="perPage" v-model="currentPage2">
                        </b-pagination>
                    </b-col>
                </b-row>
            </tab>
        </tabs>
        <br>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    data () {
        return {
            fields1: [
                { key: 'fullNum', label: 'Номер', sortable: true },
                { key: 'date', label: 'Дата', sortable: true },
                { key: 'status', label: 'Статус', sortable: true },
                { key: 'cost', label: 'Стоимость', sortable: true },
                { key: 'is_paid', label: 'Оплата', sortable: true },
                { key: 'action', label: 'Действие', sortable: true }
            ],
            fields2: [
                { key: 'fullNum', label: 'Номер', sortable: true },
                { key: 'date', label: 'Дата', sortable: true },
                { key: 'status', label: 'Статус', sortable: true },
                { key: 'cost', label: 'Стоимость', sortable: true },
                { key: 'is_paid', label: 'Оплата', sortable: true },
            ],
            items: [],
            totalRows1: 0,
            totalRows2: 0,
            currentPage1: 1,
            currentPage2: 1,
            perPage: 10,
            filter: ['Выдан/отправлен', 'Отменен']
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/claims`)
        .then(response => {
            this.items = response.data
            this.totalRows = response.data.length
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log()
            }
        });
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token;
            axios.get(this.$store.state.apiUrl + "/api/company")
                .then(response => {
                    if(response.data !==false){
                        if(response.data.is_admin==true){
                            this.$router.push('/sub-manage')
                        }else{
                            this.$router.push('/new-avtoreq')
                        }
                       
                    }
                    console.log(response);
                    //this.hasSubscription = response.data.is_admin;
                })
                .catch(error => {
                    console.error("Ошибка проверки подписки:", error);
                });


    },
    components: {
    },
    computed: {
        filter1(){
            const f = this.items.filter(f => !this.filter.includes(f.status))
             // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows1 = f.length
            return f
        },
        filter2(){
            const f = this.items.filter(f => this.filter.includes(f.status))
             // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.totalRows2 = f.length
            return f
        },
       
    },
    methods: {
        rowClick(item, index) {
            this.$store.commit('SET_LOADER', true)
           this.$router.push({ path: `/claim/${item.num}` })
        },
        action(row){
            if(row.item.status == 'На согласовании'){
                return 'Согласуйте ТЗ'
            }
            if(row.item.status == 'Ожидает оплаты'){
                return 'Оплатите заверение '
            }
            return 'Действий не требуется'
        }
    }
}
</script>

<style scoped>
   
</style>