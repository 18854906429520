<template>
    <div class="container">
        <b-breadcrumb :items="bc"/>
        <div class="center-heading" style="margin-top:30px">
            <h1 v-if="!is_update" class="section-title">Создать заказчика</h1>
            <h1 v-if="is_update" class="section-title">Изменение заказчика</h1>
        </div>
        
        <b-card header="Default" v-if="showPerson"
                header-tag="header"
                no-body>
                <h6 slot="header" class="mb-0">
                    Физ. лицо
                </h6>
                <b-form @submit.prevent="submitPerson" style="padding:20px 20px 40px 20px">
                    <b-form-group id="InputGroup1"
                                    label="Ф.И.О:"
                                    label-for="Input1">
                        <b-form-input id="Input1"
                                    type="text"
                                    v-model="formPerson.name"
                                    required
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="InputGroup2"
                                    label="Адрес"
                                    label-for="Input2">
                        <b-form-input id="Input2"
                                    type="text"
                                    v-model="formPerson.address"
                                    required
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>
                    <div class="row">
                        <div class="col-md-4">
                            <b-form-group id="InputGroup6"
                                        label="Паспорт серия номер:"
                                        label-for="Input6">
                            <b-form-input id="Input6"
                                        type="text"
                                        
                                        v-model="formPerson.passport_num"
                                        v-mask="'9999 999999'"
                                        placeholder="">
                            </b-form-input>
                        </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group id="InputGroup6"
                                            label="Паспорт дата выдачи:"
                                            label-for="Input6">
                                <b-form-input id="Input6"
                                            type="text"
                                            
                                            v-model="formPerson.passport_date"
                                            v-mask="'99.99.9999'"
                                            placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-4">
                            <b-form-group id="InputGroup6"
                                            label="Код подразделения:"
                                            label-for="Input6">
                                <b-form-input id="Input6"
                                            type="text"
                                            
                                            v-model="formPerson.passport_auth"
                                            placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                 
                    <b-form-group id="InputGroup6"
                                    label="Паспорт выдан:"
                                    label-for="Input6">
                        <b-form-input id="Input6"
                                    type="text"
                                    
                                    v-model="formPerson.passport_dep"
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>
                    
                    <b-form-checkbox id="checkbox1"
                                    v-model="formPerson.is_self_empl"
                                    value="1"
                                    unchecked-value="0">
                            Индивидуальный предприниматель
                    </b-form-checkbox>
                    <br>
                    <br>
                    <div class="row" v-if="formPerson.is_self_empl == 1">
                     <div class="col-md-6">
                            <b-form-group id="InputGroup6"
                                            label="ОГРНИП:"
                                            label-for="Input6">
                                <b-form-input id="Input6"
                                            type="text"
                                            v-model="formPerson.ogrnip"
                                            placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group id="InputGroup6"
                                            label="ИНН:"
                                            label-for="Input6">
                                <b-form-input id="Input6"
                                            type="text"
                                            v-model="formPerson.inn"
                                            placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button type="submit" class="btn-secondary-box">Сохранить</button>
                        </div>
                    </div>
                    
                </b-form>
            </b-card>
            <b-card header="Default" v-if="showCompany"
                header-tag="header"
                no-body>
                <h6 slot="header" class="mb-0">
                    Юр. лицо
                </h6>
                <b-form @submit.prevent="submitCompany" style="padding:20px 20px 40px 20px">
                    <b-form-group id="InputGroup1"
                                    label="Наименование:"
                                    label-for="Input1">
                        <b-form-input id="Input1"
                                    type="text"
                                    v-model="formCompany.name"
                                    required
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="InputGroup2"
                                    label="Адрес"
                                    label-for="Input2">
                        <b-form-input id="Input2"
                                    type="text"
                                    v-model="formCompany.address"
                                    required
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>

                     <div class="row">
                     <div class="col-md-6">
                            <b-form-group id="InputGroup6"
                                            label="ОГРН:"
                                            label-for="Input6">
                                <b-form-input id="Input6"
                                            type="text"
                                            v-model="formCompany.ogrn"
                                            placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group id="InputGroup6"
                                            label="ИНН:"
                                            label-for="Input6">
                                <b-form-input id="Input6"
                                            type="text"
                                            v-model="formCompany.inn"
                                            placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <b-form-group id="InputGroup5"
                                            label="Тип представителя:"
                                            label-for="Input5">
                                <b-form-select id="Input5"
                                            :options="company.repr_type"
                                            
                                            v-model="formCompany.repr_type">
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                           <b-form-group id="InputGroup5"
                                            label="Основание полномочий:"
                                            label-for="Input5">
                                <b-form-select id="Input5"
                                            :options="company.repr_osn"
                                            
                                            v-model="formCompany.repr_auth">
                                </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                 
                    <b-form-group id="InputGroup6"
                                    label="ФИО представителя:"
                                    label-for="Input6">
                        <b-form-input id="Input6"
                                    type="text"
                                    v-model="formCompany.repr_name"
                                    placeholder="">
                        </b-form-input>
                    </b-form-group>
                    
                    <div class="row" v-if="formCompany.repr_type == 3">
                     <div class="col-md-6">
                            <b-form-group id="InputGroup6"
                                            label="№ доверенности:"
                                            label-for="Input6">
                                <b-form-input id="Input6"
                                            type="text"
                                            v-model="formCompany.repr_dover_num"
                                            placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </div>
                        <div class="col-md-6">
                            <b-form-group id="InputGroup6"
                                            label="Дата доверенности:"
                                            label-for="Input6">
                                <b-form-input id="Input6"
                                            type="text"
                                            v-model="formCompany.repr_dover_date"
                                            placeholder="">
                                </b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button type="submit" class="btn-secondary-box">Сохранить</button>
                        </div>
                    </div>
                    
                </b-form>
			</b-card>
        <br>
    </div>
</template>

<script>
import axios from 'axios';
import AwesomeMask from 'awesome-mask'

export default {
    data () {
        return {
            bc: [
                {text: 'Список заявок', href: '/'}, 
                {text: 'Профиль пользователя', href: '/profile'},
                {text: 'Заказчик', active: true}
            ],
            formPerson:{
                name: '',
                address: '',
                passport_num: '',
                passport_date: '',
                passport_auth: '',
                passport_dep:'',
                is_self_empl: 0,
                inn: '',
                ogrnip: '',
                type: 1
            },
            formCompany:{
                name: '',
                address: '',
                ogrn: '',
                inn: '',
                repr_type: 1,
                repr_auth: 1,
                repr_name: '',
                repr_dover_num: '',
                repr_dover_date: '',
                type: 2
            },
            content: {
                regions: [],
                citys: [],
                customers: [],
            },
            company: {
                repr_type:[
                    {value:0, text:'Директор'},
                    {value:1, text:'Генеральный директор'},
                    {value:2, text:'Представитель по доверенности'},
                    {value:3, text:'Исполнительный директор'},
                    {value:4, text:'Коммерческий директор'},
                    {value:5, text:'Управляющий'},
                    {value:6, text:'Управляющий партнер'},
                    {value:7, text:'Председатель'},
                    {value:8, text:'Председатель правления'},
                    {value:9, text:'Президент'},
                ],
                repr_osn:[
                    {value: 1, text: 'Устав'},
                    {value: 2, text: 'Доверенность'},
                ],
            },
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            customer_tab: false,
            profile_tab: true,
            showPerson: true,
            showCompany: true
        }
    },
    created() {
        this.$store.commit('SET_LOADER', false)
        if(this.is_update){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.get(this.$store.state.apiUrl+`/profile/customer/`+this.$route.params.id)
            .then(response => {
                if(response.data.type == 1){
                    this.showCompany = false
                    this.showPerson = true
                    this.formPerson = response.data
                    return true
                }else if(response.data.type == 2){
                    this.showCompany = true
                    this.showPerson = false
                    this.formCompany = response.data
                    return true
                }else{
                    this.$router.push('/profile')
                }
            }).catch(error => {
                console.log(error)
            });
        }
    },
     directives: {
        'mask': AwesomeMask
    },
    components: {

    },
    computed: {
        is_update(){
            if(this.$route.params.id){
                return true
            }
            return false
        },
        submitUrl(){
            if(this.is_update){
                return this.$store.state.apiUrl+`/profile/customer/`+this.$route.params.id
            }else{
                return this.$store.state.apiUrl+`/profile/customer/create`
                
            }
        }

    },
    methods: {
        submitPerson(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.submitUrl, {
                body: this.formPerson
            }).then(response => {
                this.$router.push('/profile')
            }).catch(error => {
                console.log(error)
            });
        },
         submitCompany(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.submitUrl, {
                body: this.formCompany
            }).then(response => {
                this.$router.push('/profile')
            }).catch(error => {
                console.log(error)
            });
        },
        
    }
}
</script>

<style scoped>
   
</style>