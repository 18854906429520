<template>
  <div class="container" > 
    <div class="content" v-if="this.loaded==0">
			<div class="row" style="margin-top:30px">
				<div class="col-lg-12">
					<div class="center-heading " >
                       
    <h1 class="section-title">Реферальная программа</h1>
    
						
					</div>
          <div class="card">
    <div class="card-body">
      <h5 class="card-title">Принять участие в реферальной прогармме ?
      </h5>
      <div style="text-align:justify; font-size:16px"> Если вы юрист, адвокат, патентный поверенный или просто предприимчивый человек, вы можете получать доход от участия в нашей реферальной программе, а ваши доверители или приглашенные пользователи будут получать скидки на протоколы фиксации доказательств.</div>
     <br>
      <div class="input-group mb-3" style="display: contents;">
         <button class="btn btn-primary mr-3 "  @click="sendreq()">Принять участие</button>
      </div>
     
    </div>
  </div>
    </div>
    </div>
    </div>
    <div class="content" v-if="this.loaded==1">
			<div class="row" style="margin-top:30px">
				<div class="col-lg-12">
					<div class="center-heading " >
                       
    <h1 class="section-title">Реферальная программа</h1>
    
						
					</div>
          <div class="card">
    <div class="card-body">
      <h5 class="card-title">Ваша заявка находится на рассмотрении
      </h5>
    </div>
  </div>
    </div>
    </div>
    </div>
     <div class="content" v-if="this.loaded==3">
			<div class="row" style="margin-top:30px">
				<div class="col-lg-12">
					<div class="center-heading " >
                       
    <h1 class="section-title">Реферальная программа</h1>
    
						
					</div>
          <div class="card">
    <div class="card-body">
      <h5 class="card-title">Вам отказано в участии
      </h5>
    </div>
  </div>
    </div>
    </div>
    </div>
        <div class="content" v-if="this.loaded==2">
			<div class="row" style="margin-top:30px">
				<div class="col-lg-12">
					<div class="center-heading " >
                       
    <h1 class="section-title">Реферальная программа</h1>
    
						
					</div>
          <div class="card">
    <div class="card-body">
      <h5 class="card-title">Ваша реферальная ссылка</h5>
      <div class="input-group mb-3">
        <input type="text" class="form-control" aria-describedby="basic-addon1" v-model="referralCode" readonly>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" id="basic-addon1"  type="button" @click="copyReferralCode()"><i class="fa fa-copy"></i></button>
        </div>
      </div>
     
    </div>
  </div>
  <br>
    
  
                    <div class="card">
    <div class="card-body">
      <h5 class="card-title">Ваш промокод</h5>
      <div class="input-group mb-3">
        <input type="text" class="form-control" aria-describedby="basic-addon2" v-model="referralLink" readonly>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" id="basic-addon2"  type="button" @click="copyReferralLink() "><i class="fa fa-copy"></i></button>
        </div>
      </div>
     
    </div>
  </div>
  <br>
   <div class="card">
    <div class="card-body">
   <h5 class="card-title">Cумма накопленных бонусов:</h5>
   <div class="card-text" style="    font-weight: bold;
    font-size: xxx-large;">
   {{' '+this.data_promo.balans }}
   </div>
    </div>
  </div>
  <br>
 <div class="card">
    <div class="card-body">
      <h5 class="card-title">Статистика рефералов</h5>

      <b-table :items="data_promo.referrals" :fields="fields" :per-page="perPage" :current-page="currentPage" striped bordered hover responsive>
      
      </b-table>
      <div class="mt-3 d-flex justify-content-center">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0" />
      </div>
      <br>
      
      <div class="mt-3">
       
         <button class="btn btn-primary mr-3" @click="openPackageModal">Получить пакет протоколов</button>
    <button class="btn btn-primary" @click="openBonusModal">Вывести бонусы</button>

    <!-- Модальное окно для заказа пакетов -->
    <b-modal v-model="this.packageModalVisible" ok-title="Заказать" cancel-title="Назад" @ok="orderPackages" title="Заказ пакета протоколов">
      <span>Минимальный заказ от 10 протоколов.</span><br>
      <span>Стоимость одного протокола: 500 баллов.</span><br>
      <span style="font-size:16px">Ваш заказ</span><br>
      <input title="Заказ" style="width: 50%;" type="number" v-model="packageCount" min="10" :max="maxPackReal">
        <p style="font-size:10px">Количество доступных протоколов : {{this.maxPack}}</p>
      
    </b-modal>

    <!-- Модальное окно для вывода бонусов -->
    <b-modal v-model="this.bonusModalVisible" cancel-title="Назад" @ok="withdrawBonuses" title="Вывод бонусов">
      <span>Минимальный вывод 6000 баллов.</span><br>
       <span>1 балл = 1 рубль.</span><br>
      <span style="font-size:16px">Ваш заказ</span><br>
       <input style="width: 50%;" type="number" v-model="bonusCount" min="6000" :max="maxPackmoney"><br>
      <span style="font-size:10px">Количество доступных бонусов: {{this.data_promo.balans}}</span>
     
    </b-modal>
      </div>
      </div>
      </div>
      <br>
      <div class="card">
    <div class="card-body">
    <div class="mt-12" style="width: 100%">
  <h5>Часто задаваемые вопросы</h5>
  <div class="accordion" id="faqAccordion">
    <div class="card">
      <div class="card-header" id="headingOne">
        <h2 class="mb-0">
          <b-button variant="link" v-b-toggle.collapseOne>
            Как я могу получить свою реферальную ссылку?
          </b-button>
        </h2>
      </div>
      <b-collapse id="collapseOne" visible>
    <div class="card-body">
{{this.data_promo.ref1}}
    </div>
  </b-collapse>
</div>
<div class="card">
  <div class="card-header" id="headingTwo">
    <h2 class="mb-0">
      <b-button variant="link" v-b-toggle.collapseTwo>
        Как я могу узнать, сколько я заработал на рефералах?
      </b-button>
    </h2>
  </div>
  <b-collapse id="collapseTwo">
    <div class="card-body">
      {{this.data_promo.ref2}}
    </div>
  </b-collapse>
</div>
<div class="card">
  <div class="card-header" id="headingThree">
    <h2 class="mb-0">
      <b-button variant="link" v-b-toggle.collapseThree>
        Как я могу вывести заработанные бонусы?
      </b-button>
    </h2>
  </div>
  <b-collapse id="collapseThree">
    <div class="card-body">
      {{this.data_promo.ref3}}
    </div>
  </b-collapse>
</div>
</div>
</div>
    </div>
   </div>
   <br>
 
				</div>
			</div>
            
		</div>
        <br>
         <flash-message transition-name="list-complete" />
    </div>
</template>
<script>
require('vue-flash-message/dist/vue-flash-message.min.css');
import axios from 'axios';

  export default {
    
    data() {
      return {
       referralLink: '12345',
       referralCode: 'https://www.shotapp.ru/request?promo='+this.referralLink,
       loaded:false,
       data_promo: {
        balans: 0,
        referrals: [],
      },
      ref1:'',
      ref2:'',
      ref3:'',
      maxPackageCount: 0, // максимальное количество пакетов, доступное для заказа
      packageCount: 10, // количество заказываемых пакетов
      bonusBalance: 100, // количество бонусов пользователя
      bonusCount: 6000, // количество выводимых бонусов
      packageModalVisible: false, // флаг видимости модального окна для заказа пакетов
      bonusModalVisible: false, // флаг видимости модального окна для вывода бонусов
      fields: [
        { key: 'date', label: 'Дата', sortable: true },
        { key: 'refer', label: 'Промокод', sortable: true },
        { key: 'reward', label: 'Количество бонусов', sortable: true }
      ],
      currentPage: 1,
      perPage: 5
      };
    },
    methods: {
      openPackageModal() {
      this.maxPackageCount = Math.floor(this.balance / 500);
      this.packageCount = 10; // сбросить количество заказываемых пакетов
      this.packageModalVisible = false; // сбросить флаг видимости окна
      this.$nextTick(() => {
        this.packageModalVisible = true; // открыть окно
      });
    },
    orderPackages() {
      // выполнить заказ пакетов и обновить баланс пользователя
      if(this.maxPack>=10){
        this.flashSuccess('Заявка принята');
this.data_promo.balans -= this.packageCount * 500;
      this.maxPackageCount = Math.floor(this.data_promo.balans / 500);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
      axios.post(this.$store.state.apiUrl+`/order`, {
                type: 1,
                count: this.packageCount
            }).catch((error) => {
                console.log(error)
            });
      this.packageModalVisible = false;
      }else{
        this.flashError('Недостаточно средств');
      }
    },
    openBonusModal() {

      this.bonusModalVisible = false; // сбросить флаг видимости окна
      this.$nextTick(() => {
        this.bonusModalVisible = true; // открыть окно
      });
    },
    withdrawBonuses() {
      // выполнить вывод бонусов и обновить баланс пользователя
      if(this.data_promo.balans>=this.bonusCount){
        this.flashSuccess('Заявка принята');
      this.data_promo.balans -= this.bonusCount;
      this.bonusModalVisible = false;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
      axios.post(this.$store.state.apiUrl+`/order`, {
                type: 2,
                count: this.bonusCount
            }).catch((error) => {
                console.log(error)
            });
    }else{
this.flashError('Недостаточно средств');
    }
      this.packageModalVisible = false;
    },
      copyReferralLink() {
      const input = document.createElement('input')
      input.setAttribute('value', this.referralLink)
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
    },
    copyReferralCode() {
      const input = document.createElement('input')
      input.setAttribute('value', this.referralCode)
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      document.body.removeChild(input)
    },
    sendreq(){
axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/promo_req`)
        .then(response => {
            this.loaded = response.data
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log()
            }
        });

    }
    },
    computed: {
    totalRows() {
      return this.data_promo.referrals.length
    },
     maxPack() {
      return Math.floor(this.data_promo.balans / 500);
    },
    maxPackReal() {
      if(Math.floor(this.data_promo.balans / 500)<10){
return 10;
      }else{
        return Math.floor(this.data_promo.balans / 500);
      }
      
    },
     maxPackmoney() {
      if(this.data_promo.balans<6000){
return 6000;
      }else{
        return this.data_promo.balans;
      }
      
    },
    slicedReferrals() {
      const startIndex = (this.currentPage - 1) * this.perPage
      return this.referrals.slice(startIndex, startIndex + this.perPage)
    }
  },
     mounted() {
        this.$store.commit('SET_LOADER', false)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/promo`)
        .then(response => {
            this.loaded = response.data
            console.log(this.loaded);
            if(this.loaded==2){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/promo_d`)
        .then(response => {
            this.data_promo = response.data;
            this.referralLink=this.data_promo.refer;
       this.referralCode='https://www.shotapp.ru/request?promo='+this.data_promo.refer;

        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log()
            }
            console.log(this.loaded);
        });

            }
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log()
            }
        });
    },
  };
</script>
<style>
.card {
 
  margin: 0 auto;
  border: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  font-weight: bold;
  margin-bottom: 20px;
  color: #007bff;
}

.form-control {
  font-size: 14px;
  font-weight: bold;
}

.btn-outline-secondary {
  border-color: #007bff;
  color: #007bff;
}

.btn-outline-secondary:hover {
  background-color: #007bff;
  color: #fff;
}

</style>