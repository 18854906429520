<template>
    <div class="container">
        <b-breadcrumb :items="bc"/>
        <div class="center-heading">
            <h1 class="section-title">Техническое задание<br><span style="font-size:16px">от {{content.date}}</span> </h1>
        </div>
        
        <div v-html="content.text"></div>
        
        <b-row v-if="isNotes">
            <b-col md="12">
                <b-button variant="success" @click="ok">Всё правильно</b-button>
                <b-button variant="outline-secondary" @click="hasNotes">Есть замечания</b-button>
            </b-col>
        </b-row>
        <b-card v-if="noteShow" title="Ваши замечания">
           <b-form-textarea id="notes"
                v-model="form.remark"
                placeholder=""
                :rows="3"
                :max-rows="6">
            </b-form-textarea>
            <br>
             <b-row>
                <b-col md="12">
                    <b-button variant="success" @click="sendNote">Отправить</b-button>
                    <b-button variant="outline-danger" @click="cancelNote">Отменить</b-button>
                </b-col>
            </b-row>
        </b-card>
        <br>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    data () {
        return {
            bc: [
                {text: 'Список заявок', href: '/'}, 
                {text: 'Заявка №'+this.$route.params.num, href: '/claim/'+this.$route.params.num},
                {text: 'Техническое задание', active: true}
            ],
            form:{
                accept: 0,
                remark: ''
            },
            content: {
                date: '',
                text: ''
            },
            isNotes: true,
            noteShow: false,
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/tech/`+ this.$route.params.num)
        .then(response => {
            // console.log(response.data)
            this.content = response.data
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else{
                console.log(error)
            }
        });
    },
    components: {

    },
    computed: {
    
    },
    methods: {
        ok(){
            this.form.accept = 1;
            this.send()
            // this.$router.go('-1')
        },
        hasNotes(){
            this.isNotes = false
            this.noteShow = true
        },
        cancelNote(){
            this.isNotes = true
            this.noteShow = false
            this.form.remark = ''
        },
        sendNote(){
            this.send()
            //   this.$router.go('-1')
        },
        send(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(`https://api.webjustice.ru/tech/`+ this.$route.params.num, {
                    body: this.form
                }).then(response => {
                    this.$router.push('/claim/'+this.$route.params.num)
                }).catch(error => {
                    console.log(error)
                });
        }
    }
}
</script>

<style scoped>
   
</style>