<template>
    <div class="container">
        <b-breadcrumb :items="bc"/>
        
        <div class="content">
			<div class="row" style="margin-top:30px">
				<div class="col-lg-12">
					<div class="center-heading">
						<h1 class="section-title">Создание заявки на нотариальное заверение</h1>
					</div>
				</div>
			</div>
            <tabs>
                <tab name="Сайт">
                    <site :tariffs="tariffs" :issues="issues"></site>
                </tab>
                <tab name="Эл. почта">
                   <email :tariffs="tariffs" :issues="issues"></email>
                </tab>
            </tabs>
		</div>
        <br>
    </div>
</template>

<script>
import axios from 'axios';
import Site from '../components/request/site.vue'
import Email from './request/email.vue'

export default {
    components: {
      'site': Site,
      'email': Email,
    },
    data () {
        return {
            bc: [
                {text: 'Список заявок', href: '/'}, 
                {text: 'Создание заявки', active: true}
            ],
            tariffs: {
                object: {
                    limitSite:0
                }
            },
            issues: [],
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
         axios.get(this.$store.state.apiUrl+`/claim`)
            .then(response => {
                this.tariffs = response.data.tariffs
                this.issues = response.data.issues
            }).catch((error) => {
                // eslint-disable-next-line
                console.error(error)
            });
    },
    methods: {
       
	}
}
</script>

<style scoped>
  

</style>
