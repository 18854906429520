<template>
	<div class="container">
		<b-breadcrumb :items="bc"/>
		<div class="row">
			<div class="col-lg-12">
				<div class="center-heading" style="margin-top:30px">
					<h1 class="section-title">Файл из сообщения</h1>
				</div>
			</div>
		</div>
		<div class="text-center">
			<b-img fluid :src="image" alt="Responsive image" />
		</div>
		<div class="col-md text-center" style="padding:30px">
			<button class="btn-secondary-box" @click="$router.push('/message/'+$route.params.num)">Вернуться в сообщение</button>
		</div>
	</div>
</template>

<script>

export default {
    data () {
        return {
			bc: [
				{text: 'Список заявок', href: '/'}, 
				{text: 'Сообщения', href: '/messages'},
				{text: 'Сообщение №'+this.$route.params.num, href: '/message/'+this.$route.params.num},
				{text: 'Изображение', active: true}
			],
			image: this.$store.state.apiUrl+`/storage/msgs/`+ this.$route.params.file,
        }
    },
	created() {
		this.$store.commit('SET_LOADER', false)
		
	}, 
    methods: {
		
	},
	components: {
		
	},
}
</script>

<style scoped>
 .custom-file-input ~ .custom-file-label::after {
    content: "Файл";
}
</style>
