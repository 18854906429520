<template>
    <div class="container">
        <b-breadcrumb :items="bc"/>
        <div class="center-heading">
            <h1 class="section-title">Заявка №{{content.fullNum}}<br><span style="font-size:16px">от {{content.date}}</span></h1>
        </div>
        <div class="row">
            <div class="col-md-6">
                <b-list-group style="margin-top:20px">
                    <!-- <b-list-group-item><strong>Нотариус</strong> : {{content.notarius}}</b-list-group-item> -->
                    <!-- <b-list-group-item><strong>Специалист</strong> : {{content.spec}}</b-list-group-item> -->
                    <!-- <b-list-group-item><strong>Заявитель</strong> : {{content.declarant}}</b-list-group-item> -->
                    <b-list-group-item>
                        <strong>Статус</strong> : {{content.status}}
                        <button v-if="isDownload" class="float-right btn btn-primary btn-sm" @click="makeProtocol"><i v-if="dlLoading" class="fas fa-spinner fa-pulse"></i> Скачать протокол</button>
                        <!-- <b-link v-if="content.status != 'Отменён' && content.tzSent == 1" class="float-right" :href="`/claim/${content.num}/tech`">Посмотреть ТЗ</b-link> -->
                    </b-list-group-item>
                    <b-list-group-item :variant="!isCustomer ? 'danger' : ''">
                        <strong>Заказчик</strong> : 
                        <b-form-select v-if="!customerState" v-model="content.customer" @change="sendCustomer()" :options="customers"  size="sm" class="sel"/>
                        <!-- <a href="#" v-if="customerState && content.status == 'Новый'" @click="changeCustomer">{{customers[content.customer]}}</a> -->
                        <a href="#" v-if="customerChange" @click="customerState = false">{{customers[content.customer]}}</a>
                        <span v-if="unchangeCustomer">{{customers[content.customer]}}</span>
                        <b-link v-if="!isCustomer && customerState" href="#" @click="customerState = false">Выбрать</b-link>
                        <b-link v-if="!customerState" class="float-right" href="/profile/customer">Добавить</b-link>
                        <!-- <b-link v-if="customerChange" class="float-right" href="#" @click="customerState = false">Изменить</b-link> -->
                    </b-list-group-item>
                    <b-list-group-item :variant="content.status == 'На согласовании' && content.tzSent == 1 ? 'danger' : ''">
                        <strong>Тех. задание</strong> : 
                        <a :href="`/claim/${content.num}/tech`" v-if="content.status == 'На согласовании' && content.tzSent == 1" >Требует согласования</a>
                        <span v-if="content.pType == 2 && content.status != 'На согласовании' && content.tzSent == 1">Согласовано</span>
                        <span v-if="content.pType == 2 && content.status == 'Новый' && content.tzSent == 0">В процессе подготовки</span>
                        <span v-if="content.pType == 1">Не требуется</span>
                    </b-list-group-item>
                </b-list-group>
                
            </div>
            <div class="col-md-6">
                <b-list-group style="margin-top:20px">
                   
                    <b-list-group-item :variant="content.is_paid == 'Оплачен' ? 'success' : 'danger'">
                        <strong>Оплата</strong> : {{content.is_paid}}
                        <b-link v-if="showInvoice" class="float-right" href="#" @click="paymentDo"><i v-if="invoiceLoader" class="fas fa-spinner fa-pulse"></i> {{ paymentStatus }}</b-link>
                        <button v-if="showOnlinePayment" class="float-right btn btn-success btn-sm" @click="paymentDo"><i v-if="onlineLoader" class="fas fa-spinner fa-pulse"></i> Оплатить</button>
                    </b-list-group-item>
                    <b-list-group-item :variant="!isPaymentType ? 'danger' : ''"> 
                        <strong>Способ оплаты</strong> : 
                        <b-form-select v-if="!paymentType" v-model="content.payment_type" @change="sendPaymentType()" :options="paymentTypes" size="sm" class="sel"/>
                        <a href="#" v-if="paymentType && content.is_paid == 'Не оплачен'" @click="changePaymentType">{{paymentTypes[content.payment_type]}}</a>
                        <span v-if="paymentType && content.is_paid == 'Оплачен'">{{paymentTypes[content.payment_type]}}</span>
                        <b-link v-if="!isPaymentType && paymentType" href="#" @click="paymentType = false">Выбрать</b-link>
                        <b-link v-if="!paymentType" class="float-right text-danger" href="#" @click="closePaymentType">Закрыть</b-link>
                    </b-list-group-item>
                    <b-list-group-item :variant="!isDelivery ? 'danger' : ''">
                        <strong>Получение</strong> : 
                        <b-form-select v-if="!delivery" v-model="content.delivery" @change="sendDelivery()" :options="deliveryTypes" size="sm" class="sel"/>
                        <a href="#" v-if="delivery && content.status != 'Выдан/отправлен'" @click="changeDelivery">{{deliveryTypes[content.delivery]}}</a>
                        <span v-if="delivery && content.status == 'Выдан/отправлен'">{{deliveryTypes[content.delivery]}}</span>
                        <b-link v-if="!isDelivery && delivery" href="#" @click="delivery = false">Выбрать</b-link>
                        <b-link v-if="!delivery" class="float-right text-danger" href="#" @click="closeDelivery">Закрыть</b-link>
                        <b-link v-if="content.delivery == 2 && delivery && content.status != 'Выдан/отправлен'" class="float-right" href="#" v-b-modal.modalPrevent>Параметры доставки</b-link>
                    </b-list-group-item>
                </b-list-group>
                
            </div>
        </div>
        <b-modal id="modalPrevent"
                size="lg"
                ref="modal"
                title="Параметры доставки"
                ok-title = "Изменить"
                @ok="deliveryParamsOk"
                @shown="getDeliveryParams"
                ok-only>
            <form @submit.stop.prevent="sendDeliveryParams">
                <b-form-group id="InputGroup2"
                                    label="Адрес"
                                    label-for="Input2">
                    <b-form-input id="Input2"
                                type="text"
                                v-model="deliveryParams.address"
                                required
                                placeholder="">
                    </b-form-input>
                </b-form-group>
               <div class="row">
                   <div class="col-md-6">
                       <b-form-group id="InputGroup2"
                                    label="Получатель"
                                    label-for="Input2">
                            <b-form-input id="Input2"
                                        type="text"
                                        v-model="deliveryParams.receiver"
                                        placeholder="">
                            </b-form-input>
                        </b-form-group>
                   </div>
                   <div class="col-md-6">
                       <b-form-group id="InputGroup2"
                                    label="Телефон"
                                    label-for="Input2">
                            <b-form-input id="Input2"
                                        type="text"
                                        v-model="deliveryParams.phone"
                                        placeholder="">
                            </b-form-input>
                        </b-form-group>
                   </div>
               </div>
                <b-form-group  id="infoInputGroup" 
                                label="Инфо"
                                label-for="textTextarea">
                    <b-form-textarea id="textTextarea"
                                    v-model="deliveryParams.info"
                                    placeholder=""
                                    :rows="3"
                                    :max-rows="5">
                    </b-form-textarea>
                </b-form-group>
            </form>
        </b-modal>
        <br>
        <b-card  v-if="false" bg-variant="primary" text-variant="white">
            <strong>Метод оплаты</strong> : 
        </b-card>
            <b-card bg-variant="light" 
                header-tag="header"
                no-body>
                <h6 slot="header" class="mb-0">
                    <div v-if="!emailCredsForm" style="padding-top:5px" class="float-left">Объекты осмотра</div>
                    <div v-if="emailCredsForm" style="padding-top:5px" class="float-left">Доступ к почте</div>
                    <div v-if="this.content.status == 'Новый' && content.type == 1 && urlTableShow || this.content.status == 'На согласовании' && content.type == 1 && urlTableShow" class="float-right"><b-button size="sm" variant="outline-info" @click="addUrl">Добавить адрес</b-button></div>
                    <div v-if="content.type == 2 && emailTableShow" class="float-right"><b-button size="sm" variant="outline-info" @click="emailCreds">Доступ к почте</b-button></div>
                    <div v-if="content.type == 2 && emailCredsForm" class="float-right"><b-button size="sm" variant="outline-danger" @click="emailCreds">Назад к объектам</b-button></div>
                    <div v-if="content.type == 1 && urlShow" class="float-right"><b-button size="sm" variant="outline-danger" @click="addUrl">Назад к объектам</b-button></div>
                </h6>
                <b-table v-if="content.type == 1 && urlTableShow" show-empty responsive
                    :empty-text = "`Пусто`"
                    :fields="fields1" 
                    :items="items">
                    <template slot="num" slot-scope="row">{{row.index + 1}}</template>
                    <template slot="url" slot-scope="data">
                        <a :href="data.value" target="_blank">
                            {{data.value}}
                        </a>
                    </template>
                </b-table>
                <b-table v-if="content.type == 2 && emailTableShow" show-empty responsive
                    :empty-text = "`Пусто`"
                    :fields="fields2" 
                    :items="items">
                    <template slot="num" slot-scope="row">{{row.index + 1}}</template>
                </b-table>
                <!-- Email credentials -->
                <div v-if="content.type == 2 && emailCredsForm" class="" style="margin-top:20px">
                    <b-form @submit.prevent="sendEmailAuth">
                    <div class="col-md-12">
                        <b-form-group id="email-claim"
                                        label="Email адрес"
                                        label-for="email-c">
                            <b-form-input id="email-c"
                                        type="email"
                                        v-model="form.email_address"
                                        required
                                        placeholder="Введите Email адрес">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-12">
                        <b-form-group id="email-pass"
                                        label="Пароль от почты"
                                        label-for="email-p">
                            <b-form-input id="email-p"
                                        type="password"
                                        v-model="form.email_password"
                                        required
                                        placeholder="Введите пароль от почты">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-md-12 text-center" style="margin:30px 0">
                        <button type="submit" class="btn-secondary-box">Сохранить</button>
                    </div> 
                    </b-form>
                </div>
                <!-- Add url  -->
                
                <div class="col-md-12" v-if="content.type == 1 && urlShow" style="margin-top:20px">
                    <b-form @submit.prevent="sendUrl">
                    <b-form-group id="urlInputGroup"
                                    label="Адрес сайта"
                                    label-for="urlInput"
                                    class="form-element">
                        <b-form-input id="urlInput"
                                    type="url"
                                    v-model="object.url"
                                    required
                                    placeholder="http://www.example.ru">
                        </b-form-input>
                    </b-form-group>

                        <b-form-group id="actionsInputGroup"
                        description="Открыть изображение, открыть вкладку, авторизация,  осмотр html-кода, запись видео/др. файлов на цифровой носитель, заверение файлов/документов и тд.">
                        <b-form-checkbox id="checkbox"
                                        class="col-md-12"
                                        v-model="object.is_action"
                                        value="1"
                                        unchecked-value="0">
                        Требуются действия на странице?
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group v-if="object.is_action == 1" id="infoInputGroup"
                            class="form-element">
                        <b-form-textarea id="infoTextarea"
                                        v-model="object.actions"
                                        placeholder="Опишите дополнительные действия которые необходимо произвести на странице если таковые имеются"
                                        style="margin-bottom:0"
                                        :rows="3"
                                        :max-rows="8">
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group id="authInputGroup">
                                <b-form-checkbox :id="`auth`"
                                    class="col-md-12"
                                    v-model="object.is_auth"
                                    value="1"
                                    unchecked-value="0">
                                Требуются авторизация
                                </b-form-checkbox>
                            </b-form-group>
                    <b-form-group id="hr-desc" description="Укажите кол-во файлов/документов размещенныx на этой интернет странице">
                        <b-form-group
                            id="fieldset-horizontal"
                            label-cols-sm="10"
                            label-cols-lg="11"
                            description=""
                            label="Необходимо распечатать и заверить файлы (документы)"
                            label-for="input-horizontal"
                            class="form-element-sm"
                        >
                            <b-form-input id="input-horizontal" type="number" min="0" max="100" number v-model="object.certFilesQty" @keypress="onlyNumber"></b-form-input>
                        </b-form-group>
                    </b-form-group>

                    <b-form-group id="hr-desc" description="Укажите кол-во файлов/документов размещенныx на этой интернет странице">
                        <b-form-group
                            id="fieldset-horizontal"
                            label-cols-sm="10"
                            label-cols-lg="11"
                            description=""
                            label="Необходимо записать на оптический диск и заверить файлы (документы)"
                            label-for="input-horizontal"
                            class="form-element-sm"
                        >
                            <b-form-input id="input-horizontal" type="number" min="0" max="100" number v-model="object.saveFilesQty" @keypress="onlyNumber"></b-form-input>
                        </b-form-group>
                    </b-form-group>

                    <b-form-group id="hr-desc" description="Укажите кол-во видеофайлов размещенныx на этой интернет странице">
                        <b-form-group
                            id="fieldset-horizontal"
                            label-cols-sm="10"
                            label-cols-lg="11"
                            description=""
                            label="Необходимо записать на оптический диск и заверить видеофайлы"
                            label-for="input-horizontal"
                            class="form-element-sm"
                        >
                            <b-form-input id="input-horizontal" type="number" min="0" max="100" number v-model="object.saveVideoQty" @keypress="onlyNumber"></b-form-input>
                        </b-form-group>
                    </b-form-group>

                    <b-form-group id="htmlInputGroup">
                        <b-form-checkbox :id="`html`"
                            class="col-md-12"
                            v-model="object.is_html"
                            value="1"
                            unchecked-value="0">
                        Заверить html-код этой страницы
                        </b-form-checkbox>
                    </b-form-group>
                     <div class="col-md-12 text-center" style="margin:30px 0">
                        <button type="submit" class="btn-secondary-box">Добавить</button>
                    </div>
                    </b-form>
                </div>

            </b-card>
            <h3 v-if="content.pType == 1 || content.pType == 2 && content.tzSent == 1" style="margin-top:30px">Стоимость : {{content.cost}} руб. </h3>
            <br>
            <!-- <b-row>
                <b-col md="12"> -->
                <!-- <b-button v-if="false" variant="success" target="_blank" :href="`https://demomoney.yandex.ru/eshop.xml?shopid=${payment.shopid}&scid=${payment.scid}&sum=${content.cost}.00&customerNumber=${$store.getters.tokenUid}`" style="margin-right:10px">Оплатить</b-button> -->
                <!-- <b-button v-if="isDownload" variant="primary" @click="dlProtocol"><i v-if="dlLoading" class="fas fa-spinner fa-pulse"></i> Скачать протокол</b-button> -->
                <!-- </b-col>
            </b-row> -->
            <br>
        
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data () {
        return {
            content: {
                num:'',
                payment_type: 0,
                delivery: 0,
                customer: 0,
                cost: 0,
                pType: 0
            },
            bc: [
                {text: 'Список заявок', href: '/'}, 
                {text: 'Заявка №'+this.$route.params.num+'-RU', active: true}
            ],
            form:{
                email_address: '',
                email_password: ''
            },
            deliveryParams:{
                address: '',
                receiver: '',
                phone: '',
                info: ''
            },
            object:{
                url:'',
                is_action: 0,
                actions: '',
                is_auth: 0,
                is_html: 0,
                saveFilesQty: '',
                saveVideoQty: '',
                certFilesQty: ''
            },
            paymentYa: {},
            customers: [],
            paymentTypes: {},
            deliveryTypes: {},
            items: [],
            fields1: [
                { key: 'num', label: '№'},
                { key: 'url', label: 'Адрес', 'class': 'td-url'},
                { key: 'action', label: 'Действия', 'class': 'td-action'},
            ],
            fields2: [
                { key: 'num', label: '№'},
                { key: 'direction', label: 'Тип'},
                { key: 'email_from', label: 'От кого'},
                { key: 'email_to', label: 'Кому'},
                { key: 'date_time', label: 'Дата и время'},
                { key: 'attach', label: 'Вложения'},
            ],
            invoiceLoader: false,
            onlineLoader: false,
            selected: null,
            options: [
                { value: null, text: 'Выберите заказчика' },
            ],
            selectedPaymentType : '',
            selectedDeliveryType : '',
            paymentType : true,
            delivery : true,
            customerState: true,
            emailTableShow: true,
            urlTableShow: true,
            emailCredsForm: false,
            urlShow: false,
            dlLoading: false,
            polling: '',
        }
    },
    mounted() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
        axios.get(this.$store.state.apiUrl+`/claims/` + this.$route.params.num)
        .then(response => {
            // console.log(response.data)
            this.content = response.data
            this.items = response.data.objects
            this.paymentYa = response.data.paymentYa
            this.customers = response.data.customerList
            this.paymentTypes = response.data.paymentTypes
            this.deliveryTypes = response.data.deliveryTypes
            // if(this.content.customer == 'Не определён'){
            //     this.customerState = false
            // }
            this.$store.commit('SET_LOADER', false)
        }).catch((error) => {
            if(error.response.status == 401){
                this.$router.push('/logout')
            }else if(error.response.status == 403){
                this.$router.push('/')
            }else{
                console.log(error)
            }
        });
        
    },
    computed: {
        isCustomer(){
            if (this.content.customer != 0) {
               return true
            }
            return false
        },
        isPaymentType(){
            if (this.content.payment_type != 0) {
               return true
            }
            return false
        },
        isDelivery(){
            if (this.content.delivery != 0) {
                return true
            }
            return false
        },
        isDownload(){
            if(this.content.pType == 1){
                if (this.content.status == 'Готов к заверению' || this.content.status == 'На согласовании') {
                    return true
                }
            }
            if(this.content.pType == 2){
                if (this.content.status == 'Готов к заверению' || this.content.status == 'На согласовании') {
                    return true
                }
            }
            // if(this.content.status == 'Готов к заверению' || this.content.status == 'Заверен'){
            //     return true
            // }
            return false
        },
        isPaid(){
            if(this.content.is_paid == 'Не оплачен'){
                return true
            }
            return false
        },
        paymentStatus(){
            if(this.content.payment_type == 1){
                return 'Счёт №'+this.content.num+'.pdf'
            }
            if(this.content.payment_type == 3){
                return 'Оплатить'
            }
            if(this.content.payment_type == 2){
                return 'Оплата при получениии'
            }
            return 'Не выбран метод оплаты'
        },
        showOnlinePayment(){
            if(this.content.pType == 1 && this.content.payment_type == 3 && this.content.is_paid != 'Оплачен' && this.content.status == 'Ожидает оплаты' && this.content.customer != 0){ //
                return true
            }
            if(this.content.pType == 2 && this.content.payment_type == 3 && this.content.is_paid != 'Оплачен' && this.content.status == 'Ожидает оплаты' && this.content.customer != 0){
                return true
            }
            return false
        },
        showInvoice(){
            if(this.content.pType == 1 && this.content.payment_type == 1 && this.content.is_paid != 'Оплачен' && this.content.customer != 0 && this.content.status == 'Ожидает оплаты'){
                return true
            }
            if(this.content.pType == 2 && this.content.payment_type == 1 && this.content.is_paid != 'Оплачен' && this.content.customer != 0 && this.content.status == 'Ожидает оплаты' && this.content.tzSent == 1){
                return true
            }
            return false
        },
        customerChange(){
            if(this.content.customer != 0 && this.customerState && this.content.status == 'Новый'){
                return true
            }
            if(this.content.customer != 0 && this.customerState && this.content.status == 'На согласовании'){
                return true
            }
            if(this.content.customer != 0 && this.customerState && this.content.status == 'Ожидает оплаты'){
                return true
            }
           
            return false
        },
        unchangeCustomer(){
            if(this.content.customer != 0 && this.content.status == 'Новый'){
                return false
            }
            if(this.content.customer != 0 && this.content.status == 'На согласовании'){
                return false
            }
            if(this.content.customer != 0 && this.content.status == 'Ожидает оплаты'){
                return false
            }
           
            return true
        },
    },
    components: {

    },
    methods: {
    
        onlyNumber ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46  && keyCode !== 8) { // 46 delete, 8 backspace
                    $event.preventDefault();
            }
        },
        emailCreds(){
            if(this.emailTableShow) {
                this.emailTableShow = false
                this.emailCredsForm = true
            }else{
                this.emailTableShow = true
                this.emailCredsForm = false
            }
        },
        addUrl(){
            if(this.urlTableShow) {
                this.urlTableShow = false
                this.urlShow = true
            }else{
                this.urlTableShow = true
                this.urlShow = false
            }
        },
        paymentDo(){
            if(this.content.payment_type == 1){
                this.invoiceLoader = true
                axios({
                    url: this.$store.state.apiUrl+'/invoice/'+ this.content.num,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.invoiceLoader = false
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Счёт №'+this.content.num+'.pdf');
                    document.body.appendChild(link);
                    link.click();
                }).catch((error) => {
                    this.invoiceLoader = false
                    console.log(error);
                });
                
            }
            if(this.content.payment_type == 3){
                this.onlineLoader = true
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
                axios.post(this.$store.state.apiUrl+`/ya/payment/` + this.content.num, {
                   
                }).then(response => {
                    if(response.data.confirmation_url){
                        const link = document.createElement('a');
                        link.href = response.data.confirmation_url;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        this.onlineLoader = false
                        // return window.open(response.data.confirmation_url)
                    }else{
                        this.onlineLoader = false
                        console.log(response);
                    }
                }).catch((error) => {
                    this.onlineLoader = false
                    console.log(error);
                });
                
            }
            return false
        },
        closePaymentType(){
            return this.paymentType = true
        },
        closeDelivery(){
            return this.delivery = true
        },
        changePaymentType(){
            if(this.content.is_paid == 'Оплачен'){
                return this.paymentType = true
            }
            if(this.content.status == 'Заверен' || this.content.status == 'Выдан/отправлен' || this.content.status == 'Отменён'){
                return true
            }
            this.paymentType = false
            
        },
        changeDelivery(){
            if(this.content.status == 'Заверен' || this.content.status == 'Выдан/отправлен' || this.content.status == 'Отменён'){
                return true
            }
            this.delivery = false
            return true
        },
        changeCustomer(){
            if(this.content.status == 'Заверен' || this.content.status == 'Выдан/отправлен' || this.content.status == 'Отменён'){
                return true
            }
            this.customerState = false
            return true
        },
        sendDelivery(){
            setTimeout(() => {
                axios.post(this.$store.state.apiUrl+`/claims/` + this.$route.params.num, {
                    body: {delivery: this.content.delivery}
                }).then(response => {
                 
                   this.delivery = true;
                   return response;
                }).catch((error) => {
                    console.log(error);

                });
                
            }, 10);
        },
        sendPaymentType(){
            setTimeout(() => {
                axios.post(this.$store.state.apiUrl+`/claims/` + this.$route.params.num, {
                    body: {payment_type: this.content.payment_type}
                }).then(response => {
                   this.paymentType = true;
                   return response;
                }).catch((error) => {
                    console.log(error);
                });
            }, 10);
        },
        sendCustomer(){
            setTimeout(() => {
                axios.post(this.$store.state.apiUrl+`/claims/` + this.$route.params.num, {
                    body: {customer: this.content.customer}
                }).then(response => {
                   this.customerState = true;
                   return response;
                }).catch((error) => {
                    console.log(error);
                });
            }, 10);
        },
        sendEmailAuth(){
             axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/claims/` + this.$route.params.num, {
                body: this.form
            }).then(response => {
                this.emailCredsForm = false
                this.emailTableShow = true
                return response;
            }).catch((error) => {
                console.log(error);
            });
        },
        sendUrl(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/claims/` + this.$route.params.num, {
                body: this.object
            }).then(response => {
                location.reload();
                return response;
            }).catch((error) => {
                console.log(error);
            });
        },
        dlProtocol() {
            // this.dlLoading = true
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios({
                url: this.$store.state.apiUrl+'/claim/download/'+ this.content.num,
                method: 'GET',
                responseType: 'blob', // important
            }).then((response) => {
                this.dlLoading = false
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Протокол №'+this.content.num+'.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch((error) => {
                this.dlLoading = false
                console.log(error);
            });
            
        },
        makeProtocol() {
            this.dlLoading = true
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+'/claim/make/'+ this.content.num,{
                body: '',
            }).then((response) => {
                if(response.status == 201){
                    this.polling = setInterval(() => {this.checkProtocol(this.content.num)}, 3000);
                }else{
                    this.$bvToast.toast(`Не удалось сформировать протокол`, {
                        title: 'Ошибка',
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: 'danger',
                        autoHideDelay: 5000,
                    })
                }
            }).catch((error) => {
                this.dlLoading = false
                this.$bvToast.toast(`Не удалось сформировать протокол`, {
                    title: 'Ошибка',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: 'danger',
                    autoHideDelay: 5000,
                })
                console.log(error);
            });
        },
        checkProtocol(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.get(this.$store.state.apiUrl+`/claim/check/` + this.$route.params.num)
            .then(response => {
                console.log(response.data)
                if(response.data == 'done'){
                    clearInterval(this.polling)
                    this.dlProtocol(this.content.num)
                }
                if(response.data == 'none'){
                    clearInterval(this.polling)
                    this.dlLoading = false
                    this.$bvToast.toast(`Не удалось сформировать протокол`, {
                        title: 'Ошибка',
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: 'danger',
                        autoHideDelay: 5000,
                    })
                }
            }).catch((error) => {
                if(error.response.status == 401){
                    this.$router.push('/logout')
                }else if(error.response.status == 403){
                    this.$router.push('/')
                }else{
                    console.log(error)
                }
            });
        },
        getDeliveryParams(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.get(this.$store.state.apiUrl+`/claims/` + this.$route.params.num + `/delivery`)
            .then(response => {
                // console.log(response.data)
                this.deliveryParams = response.data
            }).catch((error) => {
                if(error.response.status == 401){
                    this.$router.push('/logout')
                }else if(error.response.status == 403){
                    this.$router.push('/')
                }else{
                    console.log(error)
                }
            });
        },
        deliveryParamsOk(){
            this.sendDeliveryParams()
        },
        sendDeliveryParams(){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters.token
            axios.post(this.$store.state.apiUrl+`/claims/` + this.$route.params.num + `/delivery`, {
                body: this.deliveryParams
            }).then(response => {
                return response;
            }).catch((error) => {
                console.log(error);
            });
        }
    }
}
</script>

<style scoped>
   .sel {
       width:50%;
   }
   .section-title{
       margin-bottom: 0;
   }
    #fieldset-horizontal {
       margin-bottom: -5px;
   }
   #infoTextarea {
       margin-bottom: 0;
   }
</style>