<template>
    <div class="text-center">
        <img class="mb-4" src="/assets/images/logo.svg" alt="" width="120" height="120">
        <form class="form-signin" @submit.prevent="onSubmit">
            
            <h1 class="h3 mb-3 font-weight-normal">Авторизация</h1>
            <label for="selectCountry" class="sr-only">Код страны</label>
            <b-input-group id="selectCountry" class="country">
                 <b-input-group-prepend is-text class="icon">
                    <i class="fa fa-globe"></i>
                </b-input-group-prepend>
                <b-form-select id="country" 
                        :options="countries" 
                        required 
                        v-model="form.country"
                        
                >
                </b-form-select>
            </b-input-group>
            <label for="inputPhone" class="sr-only">Телефон</label>
            <b-input-group class="phone" >
                <b-input-group-prepend is-text class="icon">
                    <i class="fa fa-phone"></i>
                </b-input-group-prepend>
                <input type="text" id="inputPhone" class="form-control" v-mask="'(999)-999-99-99'" placeholder="Телефон" v-model="form.phone" required="">
            </b-input-group>
            <label for="inputPassword" class="sr-only">Пароль</label>
            <b-input-group>
                <b-input-group-prepend is-text class="icon">
                    <i class="fa fa-lock"></i>
                </b-input-group-prepend>
                <input  type="password" id="inputPassword" class="form-control" placeholder="Пароль" v-model="form.password" required="">
            </b-input-group>
            <br>
            
                <div class="col-xs-12 text-center" style="margin-bottom:20px">
                    <a href="/restore">Не могу войти</a>
                </div>

            <p v-if="errorMsg != ''" class="text-danger">{{errorMsg}}</p>
            <button class="btn-secondary-box" type="submit" style="padding: 0 50px" :disable="btnBlock"><i v-if="btnBlock" class="fas fa-spinner fa-pulse"></i> Войти</button>
            
            <p style="margin-top:30px;font-size:12px" class="text-muted">* Для входа в личный кабинет введите телефон, указанный при оформлении заявки, и ваш пароль</p>
            
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import AwesomeMask from 'awesome-mask'

export default {
    data () {
        return {
            form: {
                phone: '',
                password: '',
                country:'ru',
            },
            errorMsg: '',
            btnBlock: false,
            countries: {'ru':'Россия +7'}
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
        axios.get(this.$store.state.apiUrl+`/auth/login`)
            .then(response => {
                this.countries = response.data
                return true
            }).catch(error => {
                console.log(error)
            });
    },
     directives: {
        'mask': AwesomeMask
    },
    methods: {
       onSubmit (evt) {
            this.btnBlock = true
            axios.post(this.$store.state.apiUrl+`/auth/login`, {
                body: this.form
            }).then(response => {
                const token = response.data
                localStorage.setItem('t', token)
                this.$store.commit('SET_TOKEN', token);
                this.btnBlock = false;
                this.$router.push('/')
            }).catch((error) => {
                if(error.response.status == 401){
                    this.errorMsg = 'Введите правильный телефон или пароль'
                    this.btnBlock = false
                }else{
                    console.log(error)
                    this.errorMsg = 'Произошла ошибка авторизации'
                    this.btnBlock = false
                }
            });
        },
	},
	components: {
		
	}
}
</script>

<style scoped>
   input:invalid {
    box-shadow: none;
}
   .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: 0 auto;
    }

    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }
    .form-signin input[type="text"] {
        margin-bottom: -1px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .form-signin input[type="password"] {
        /* margin-bottom: 10px; */
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .phone .input-group-text {
        border-bottom-left-radius: 0;
        margin-bottom: -1px;
    }

    #inputPhone {
        border-top-right-radius: 0;
    }

    #country {
        border-bottom-right-radius: 0;
        margin-bottom: -1px;
        height:45px;
        box-shadow: none;
    }

    .country .input-group-text {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 0;
        margin-bottom: -1px;
    }

    .icon .input-group-text {
        border-top-left-radius: 0;
        /* padding-right: 14px;
        padding-left: 14px; */
        font-size:14px;
        width: 38px;
    }

    .input-group-text {
        border-color: #CEE1F8 !important;
    }

    ::placeholder { 
        opacity: 0.4; 
    }

</style>
