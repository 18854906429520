<template>
    <div>
        <transition name="fade">
            <div class="loading-wrapper" v-if="$store.getters.loader">
                <div class="loading">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </transition>
        <header-block v-if="loggedIn"></header-block> 
        <router-view class="main"></router-view>
        <help-block v-if="loggedIn && $route.path != '/message/new'"></help-block> 
        <footer-block v-if="loggedIn"></footer-block>
    </div>
</template>

<script>
import index from './components/index.vue'
import headerBlock from './components/header.vue'
import footerBlock from './components/footer.vue'
import helpBlock from './components/help.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export default {
    components: {
        'header-block': headerBlock,
        'footer-block': footerBlock,
        'help-block': helpBlock
    },
    data () {
        return {
            
        }
    },
    methods: {
    },
    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn
        }
    },
}
</script>

<style>
    .main {
        padding-top: 100px;
        min-height: calc(100vh - 60px);
    }
</style>
