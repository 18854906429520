import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import Routes from './routes';
import store from './store';
// import auth from './auth';
import BootstrapVue from 'bootstrap-vue';
import Tabs from 'vue-tabs-component';
import VueFlashMessage from 'vue-flash-message';
Vue.use(VueFlashMessage,{
  messageOptions: {
    timeout: 5000,
  }
});
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(Tabs);

const router = new VueRouter({
  routes: Routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters.loggedIn) {
      next()
    } else {
      next('/login')
    }
  } else {
    next() // make sure to always call next()!
  }
})

new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
});