<template>
	<div class="container">
		<a href="/message/new">
		<div class="help">
			<i class="far fa-comment text-primary"></i>
			Есть вопросы?
		</div>
		</a> 
	</div>
</template>

<script>

export default {
    data () {
        return {
          items:[]
        }
    },
	created() {
       
    },
    methods: {
     
    }
}
</script>

<style scoped>
	
</style>