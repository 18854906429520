<template>
    <div class="text-center">
        <img class="mb-4" src="/assets/images/logo.svg" alt="" width="120" height="120">
        <h1 class="h4 mb-3 font-weight-normal">Восстановление доступа</h1>

        <form v-if="!changeSuccess" class="form-signin" @submit.prevent="submitChangePass">
            <label for="selectCountry" class="sr-only">Код страны</label>
            <b-input-group id="selectCountry" class="country">
                 <b-input-group-prepend is-text class="icon">
                    <i class="fa fa-globe"></i>
                </b-input-group-prepend>
                <b-form-select id="country" 
                        :options="countries" 
                        required 
                        v-model="country"
                        
                >
                </b-form-select>
            </b-input-group>
            <label for="inputPhone" class="sr-only">Телефон</label>
            <b-input-group class="phone">
                <b-input-group-prepend is-text class="icon">
                    <i class="fa fa-phone"></i>
                </b-input-group-prepend>
                <input type="text" id="inputPhone" class="form-control" v-mask="'(999)-999-99-99'" placeholder="Телефон" v-model="phone" required="">
            </b-input-group>
          
            <br>
            <br>

            <p v-if="errorMsg != ''" class="text-danger">{{errorMsg}}</p>
            <button class="btn-secondary-box" type="submit" style="padding: 0 50px" :disable="btnBlock"><i v-if="btnBlock" class="fas fa-spinner fa-pulse"></i> Отправить</button>
        </form>
        <div v-if="changeSuccess" class="col-xs-12">
                <span>Письмо с инструкциями успешно отправлено<br>на {{email}} </span>
                <br>
                <br>
                <a href="/login">Перейти на страницу авторизации</a>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AwesomeMask from 'awesome-mask'

export default {
    data () {
        return {
            errorMsg: '',
            btnBlock: false,
            phone: '',
            changeSuccess: false,
            email: '',
            country: 'ru',
            countries: {'ru':'Россия +7'}
        }
    },
    mounted() {
        this.$store.commit('SET_LOADER', false)
        axios.get(this.$store.state.apiUrl+`/auth/login`)
        .then(response => {
            this.countries = response.data
            return true
        }).catch(error => {
            console.log(error)
        });
    },
     directives: {
        'mask': AwesomeMask
    },
    methods: {
        submitChangePass(){
            axios.post(this.$store.state.apiUrl+`/auth/change`, {
                phone: this.phone,
                country: this.country,
            }).then(response => {
                this.email = response.data.email
                this.errorMsg = ''
                this.changeSuccess = true
                this.btnBlock = false
            }).catch((error) => {
                if(error.response.status == 404){
                    this.errorMsg = 'Телефонный номер не найден'
                    this.btnBlock = false
                }else{
                    console.log(error)
                    this.errorMsg = 'Произошла ошибка'
                    this.btnBlock = false
                }
            });
        },
        
	},
	components: {
		
	}
}
</script>

<style scoped>
   input:invalid {
    box-shadow: none;
}
   .form-signin {
        width: 100%;
        max-width: 330px;
        padding: 15px;
        margin: 0 auto;
    }

    .form-signin .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
    }
    .form-signin .form-control:focus {
        z-index: 2;
    }
    .form-signin input[type="text"] {
        margin-bottom: -1px;
        /* border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; */
    }
    .form-signin input[type="password"] {
        /* margin-bottom: 10px; */
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
   

    .phone .input-group-text {
        /* border-bottom-left-radius: 0; */
        margin-bottom: -1px;
    }

    #inputPhone {
        border-top-right-radius: 0;
    }

    #country {
        border-bottom-right-radius: 0;
        margin-bottom: -1px;
        height:45px;
        box-shadow: none;
    }

    .country .input-group-text {
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 0;
        margin-bottom: -1px;
    }

    .icon .input-group-text {
        border-top-left-radius: 0;
        /* padding-right: 14px;
        padding-left: 14px; */
        font-size:14px;
        width: 38px;
    }

    .input-group-text {
        border-color: #CEE1F8 !important;
    }

    ::placeholder { 
        opacity: 0.4; 
    }

    .email .input-group-text {
        padding-right: 14px;
        padding-left: 14px;
    }

</style>
